import {
    require
} from '../utils/http'

// 我的项目列表
function p_list(data) {
    return require('get', '/api/project/v1/p_list/', data)
}

// 我的项目 职位
function jd_list(data) {
    return require('get', '/api/project/v1/jd_list/', data)
}
// 客服列表
function get_staff(data) {
    return require('get', '/api/user/v1/get_staff/', data)
}
// 分配项目给客服
function allot_project(data) {
    return require('post', '/api/project/v1/allot_project/', data)
}
// 职位中查看 已排班/上岗零工信息
function jd_odd_job(data) {
    return require('get', '/api/project/v1/jd_odd_job/', data)
}
//查看零工 工作排班时间
function get_odd_wt(data) {
    return require('get', '/api/odd_job_radar/v1/get_odd_wt/', data)
}
//职位列表查看职位详情
function jd_info(data) {
    return require('get', '/api/project/v1/jd_info/', data)
}

//查看职位招聘进度接口
function jd_schedule(data) {
    return require('get', '/api/project/v1/jd_schedule/', data)
}
// 查看零工 所有工作排班和考勤时间
function get_odd_arrange(data) {
    return require('get', '/api/project/v1/get_odd_arrange/', data)
}
// 修改排班
function update_arrange(data) {
    return require('post', '/api/project/v1/update_arrange/', data)
}

//客户对零工评价
function set_eval(data) {
    return require('post', '/api/clearing/v1/set_eval/', data)
}

//修改客服人员信息
function cost_management(data) {
    return require('GET', '/api/clearing/v1/cost_management/', data)
}


export {
    p_list,
    jd_list,
    get_staff,
    allot_project,
    jd_odd_job,
    get_odd_wt,
    jd_info,
    jd_schedule,
    get_odd_arrange,
    update_arrange,
    set_eval,
    cost_management
}