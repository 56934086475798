<template>
  <div v-loading="loading">
    <div class="top_wrap">
      <div v-if="tr_id != 4">
        <el-select
          v-model="project_id"
          @change="get_detailed"
          placeholder="请选择项目"
        >
          <el-option
            v-for="item in data_list"
            :key="item.user_detail_id"
            :label="item.company_short_name"
            :value="item.user_detail_id"
          >
          </el-option>
        </el-select>
      </div>
      <div v-else></div>
      <div class="balance">
        <div v-if="balance != '无'" style="display: flex; align-items: center">
          <i class="iconfont icon-qianbao1"></i>
          <div style="margin-top: 4px">
            <span style="font-size: 16px">钱包余额：</span
            ><span style="">{{ balance }} </span
            ><span style="font-size: 16px">元</span>
          </div>
        </div>
      </div>
    </div>
    <div class="list_wrap" v-if="list.length != 0">
      <div v-for="(item, index) in list" :key="index">
        <div
          class="list"
          v-if="item.type == 'top_up'"
          @click="data_details = item"
          slot="reference"
        >
          <div class="img">
            <svg
              t="1642494917985"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="40321"
              width="30"
              height="30"
            >
              <path
                d="M597.3504 614.4H665.6c10.24 0 17.0496-6.8096 17.0496-17.0496s-6.8096-17.0496-17.0496-17.0496h-68.2496c-10.24 0-17.0496 6.8096-17.0496 17.0496-0.0512 10.24 6.8096 17.0496 17.0496 17.0496z"
                fill="#f59213"
                p-id="40322"
              ></path>
              <path
                d="M512 0C229.2224 0 0 229.2224 0 512s229.2224 512 512 512 512-229.2224 512-512S794.7776 0 512 0z m256 665.6c0 29.0304-22.1696 51.2-51.2 51.2H307.2c-29.0304 0-51.2-22.1696-51.2-51.2V443.7504h512V665.6z m0-256H256V358.4c0-29.0304 22.1696-51.2 51.2-51.2h409.6c29.0304 0 51.2 22.1696 51.2 51.2v51.2z"
                fill="#f59213"
                p-id="40323"
              ></path>
            </svg>
          </div>
          <div class="title">
            <span>项目名：{{ item.company_short_name }}</span>
            <span>发布需求：{{ item.jd_title }}</span>
            <span class="time">支付时间：{{ item.request_time }}</span>
          </div>
          <div class="salary">
            <span style="color: #f59213; font-weight: bold"
              >订单总费用：{{ item.pay_charge }}元</span
            >
          </div>
        </div>
        <div
          class="list"
          v-if="item.type == 'punch'"
          @click="data_details = item"
          slot="reference"
        >
          <div class="img">
            <svg
              t="1642494880481"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="40083"
              width="30"
              height="30"
            >
              <path
                d="M512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m286.4896 668.16a78.2336 78.2336 0 0 1-78.208 78.208H303.1808A78.2336 78.2336 0 0 1 224.9728 668.16V355.3536a78.1312 78.1312 0 0 1 78.1568-78.2336h417.1008a78.2336 78.2336 0 0 1 78.208 78.2336v91.1616h-208.5632a65.2032 65.2032 0 1 0 0 130.4064h208.6144z m-143.36-156.3904a39.1168 39.1168 0 1 1-39.1168-39.0912 39.1168 39.1168 0 0 1 39.0656 39.1936z"
                fill="#01be4d"
                p-id="40084"
              ></path>
            </svg>
          </div>
          <div class="title">
            <span>项目名：{{ item.company_short_name }}</span>
            <span>零工工资：{{ item.jd_title }}</span>
            <span>零工姓名：{{ item.elite_name }}</span>
            <span class="time">支付时间：{{ item.request_time }}</span>
          </div>
          <div class="salary">
            <span style="color: #01be4d; font-weight: bold"
              >零 工 提 现：- {{ item.total_fee }}元</span
            >
          </div>
        </div>
      </div>

      <div class="pagination">
        <el-pagination
          background
          @current-change="Turn"
          layout="prev, pager, next"
          :total="cost_count"
          :page-size="15"
        >
        </el-pagination>
      </div>
    </div>
    <div class="shangxiajia" v-else>
      <span>暂无明细</span>
    </div>

    <div
      class="eject_wrap"
      @click="data_details = {}"
      v-if="JSON.stringify(data_details) != '{}'"
    >
      <!-- 充值 -->
      <div class="eject" v-if="data_details.type == 'top_up'" @click.stop>
        <div class="img" @click="data_details = {}">
          <i class="icon-guanbi iconfont"></i>
        </div>
        <div class="title">明细</div>
        <div>工作日期：{{ data_details.work_day | work_dayfifters }}</div>
        <div>工作时间：{{ data_details.work_hour }}</div>
        <div>用工人数：{{ data_details.jd_people }}人</div>
        <div>岗位薪资：{{ data_details.jd_salary }}元/小时</div>
        <div style="margin-bottom: 10px">
          时长：{{ data_details.work_hours }}小时
        </div>
        <div>工资：{{ data_details.all_jd_salary }}元</div>
        <div>服务费：{{ data_details.service_charge }}元</div>
        <div>总费用：{{ data_details.pay_charge }}元</div>
            <div>抵扣金额：{{ data_details.deduction_odd_salary }}元</div>
            <div>实付金额：{{ data_details.total_fee }}元</div>
      </div>
      <!-- 零工打卡提现 -->
      <div class="eject" v-if="data_details.type == 'punch'" @click.stop>
        <div class="img" @click="data_details = {}">
          <i class="icon-guanbi iconfont"></i>
        </div>
        <div class="title">明细</div>
        <div>工作开始时间：{{ data_details.work_start_time }}</div>
        <div style="margin-bottom: 10px">
          工作结束时间：{{ data_details.work_end_time }}
        </div>
        <div>上班打卡时间：{{ data_details.punch_start_time }}</div>
        <div style="margin-bottom: 10px">
          下班打卡时间：{{ data_details.punch_end_time }}
        </div>
        <div>岗位薪资：{{ data_details.jd_salary }}元/小时</div>
        <div>工资：{{ data_details.total_fee }}元</div>
      </div>
    </div>
  </div>
</template>

<script>
import { p_list } from "@/api/project";
import { wallet_detail } from "@/api/wallet";
export default {
  filters: {
    work_dayfifters(value) {
      if (value == undefined) {
        return;
      }
      let time = value.split(",");
      let length = time.length;
      if (length > 1) {
        return (
          time[0].split("-")[0] +
          "年" +
          time[0].split("-")[1] +
          "月" +
          time[0].split("-")[2] +
          "日" +
          " - " +
          time[length - 1].split("-")[0] +
          "年" +
          time[length - 1].split("-")[1] +
          "月" +
          time[length - 1].split("-")[2] +
          "日"
        );
      } else {
        return (
          time[0].split("-")[0] +
          "年" +
          time[0].split("-")[1] +
          "月" +
          time[0].split("-")[2] +
          "日"
        );
      }
    },
  },
  data() {
    return {
      loading: false,
      // 权限
      tr_id: 0,
      // 项目列表
      data_list: [],
      // 项目id
      project_id: "",
      // 页码
      page: 0,
      // 总数量
      cost_count: 0,
      // 明细列表
      list: [],
      // 详情
      data_details: {},
      //余额
      balance: "无",
    };
  },
  methods: {
    // 明细列表
    wallet_detail() {
      this.loading = true;
      wallet_detail({ user_detail_id: this.project_id, page: this.page }).then(
        (res) => {
          if (!res.code) {
            this.cost_count = res.data.cost_count;
            this.list = res.data.cost_list;
            this.balance = res.data.balance;
            this.loading = false;
          }
        }
      );
    },
    //获取列表
    getlist() {
      this.loading = true;
      p_list().then((res) => {
        if (!res.code) {
          if (this.tr_id == 4) {
            this.project_id = res.data.project_list[0].user_detail_id;
          } else {
            this.data_list = res.data.project_list;
          }
          this.wallet_detail();
          // console.log(res.data.project_list);
        }
      });
    },
    // 获取明细
    get_detailed() {
      this.page = 0;
      this.wallet_detail();
    },
    // 翻页
    Turn(val) {
      this.page = val - 1;
      this.wallet_detail();
    },
  },
  created() {
    sessionStorage.setItem("nav_id", 7);
    this.tr_id = sessionStorage.getItem("tr_id");
    this.getlist();
  },
};
</script>

<style lang="less" scoped>
.top_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .balance {
    width: 290px;
    font-size: 20px;
    i {
      color: red;
      font-size: 30px;
      margin-right: 5px;
    }
  }
}
.list_wrap {
  padding: 3%;
  .list {
    display: flex;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-bottom: 1%;
    box-shadow: 1px 2px 5px #e3e0e0;
    align-items: center;
    .title {
      width: 82%;
      display: flex;
      span {
        margin-right: 40px;
      }
    }
    .img {
      margin-right: 20px;
    }
  }
}
.shangxiajia {
  text-align: center;
  padding: 250px 0;
  color: #ccc;
}
.eject_wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 99;
  .eject {
    width: 400px;
    padding: 60px;
    background-color: #fff;
    border-radius: 3px;
    position: relative;
    .img {
      position: absolute;
      top: 5px;
      right: 10px;
      cursor: pointer;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      text-align: center;
      color: #000;
      margin-bottom: 20px;
    }
    div {
      font-size: 18px;
      line-height: 40px;
      color: #606266;
    }
  }
}
.pagination {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}
</style>