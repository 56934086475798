<template>
  <div v-loading="loading">
    <div class="calendar" v-if="JSON.stringify(this_month) != '{}'">
      <div class="timetop">
        <div @click="preTime" style="cursor: pointer">
          <i class="iconfont icon-shangyiye"></i>
        </div>
        <div class="timetop_title">{{ this_month[0].time }}</div>
        <div @click="nextTime" style="cursor: pointer">
          <i class="iconfont icon-xiayiye"></i>
        </div>
      </div>
      <div class="week">
        <div v-for="(item, index) in week" :key="index">{{ item }}</div>
      </div>
      <div>
        <div class="time_list">
          <div
            class="itemlist_div"
            v-for="(item, index) in this_month[0].item_children"
            :key="index"
          >
            <div
              @click="new_catdate(item)"
              :class="{
                TimeSelect:
                  dateTime.indexOf(item.item_title + '-' + item.item_text) !=
                  -1,
                oldSelect:
                  Object.keys(SchedulingTime).indexOf(
                    item.item_title + '-' + item.item_text
                  ) != -1,
              }"
            >
              {{ item.item_text }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="list">
      <el-table @expand-change="load" :data="data_list" style="width: 100%">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table :data="scope.row.children" class="two-list">
              <el-table-column prop="day" label="日期" width="120">
              </el-table-column>
              <el-table-column prop="resume_name" label="姓名" width="120">
              </el-table-column>
              <el-table-column prop="weixin_gender" label="性别" width="120">
                <template slot-scope="scope">{{
                  scope.row.weixin_gender | gender
                }}</template>
              </el-table-column>
              <el-table-column
                v-if="tr_id != 4"
                prop="resume_phone"
                label="手机号"
                width="150"
              ></el-table-column
              ><el-table-column
                prop="id_card_number"
                label="身份证号"
                width="220"
              >
              </el-table-column>
              <el-table-column
                prop="contract_salary"
                label="岗位薪资（时薪）"
                width="150"
              >
              </el-table-column>

              <el-table-column
                prop="punch_card_up"
                label="上班打卡时间"
                width="136"
              >
              </el-table-column>
              <el-table-column
                prop="punch_card_down"
                label="下班打卡时间"
                width="136"
              >
              </el-table-column>
              <el-table-column
                prop="clearing_time"
                label="上工时长（小时）"
                width="150"
              >
              </el-table-column>
              <el-table-column
                prop="odd_salary"
                label="合计工资（元）"
                width="120"
              >
              </el-table-column>
              <el-table-column prop="clear_status_str" label="结算" width="100">
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="company_name" label="项目" width="200">
        </el-table-column>
        <el-table-column prop="jd_title" label="职位" width="200">
        </el-table-column>
        <el-table-column prop="principal" label="负责人" width="300">
        </el-table-column>
        <el-table-column
          prop="contract_salary_min"
          label="岗位薪资/小时"
          width="120"
        >
        </el-table-column>
        <el-table-column prop="jd_people_min" label="所需人数" width="120">
        </el-table-column>
        <el-table-column prop="work_count" label="上岗人数" width="120">
        </el-table-column>
        <el-table-column prop="total_cost" label="合计费用" width="120">
        </el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { c_list, c_detail, account } from "@/api/settlement";
export default {
  data() {
    return {
      loading: false,
      // 权限
      tr_id: "",
      data_list: [],
      //当前年月日
      isyear: "",
      ismonth: "",
      isday: "",
      // 职位ID
      jd_id: "",

      // 排班的时间
      SchedulingTime: {},
      // 旧的
      week: ["日", "一", "二", "三", "四", "五", "六"],
      time_frame: "", // 时间范围
      days: "", // 周的下标
      dateTime: [], // 日期时间
      // 新的
      Warehouse: {}, // 仓库
      this_month: {}, // 当前月份
      item_children: [],

      // 选中的列表
      Select_list: [],
    };
  },
  methods: {
    load(row) {
      console.log(row.children);
      if (row.children == undefined) {
        this.jd_id = row.jd_id;
        row["children"] = [];
        this.loading = true;
        // 详情列表接口
        c_detail({ jd_id: this.jd_id, select_day: String(this.dateTime) }).then(
          (res) => {
            if (!res.code) {
              row["children"].push(...res.data.clearing_list);
              this.loading = false;
              this.$forceUpdate();
            }
          }
        );
      } else {
        delete row["children"];
      }
      console.log(row);
    },
    // 结算
    onsettlement(val, num) {
      console.log(val, num);
      account({ clear_id: val.clear_id, clear_status: num }).then((res) => {
        if (!res.code) {
          this.$message.success("结算成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取列表
    c_list(select_day) {
      this.loading = true;
      c_list({ select_day: select_day }).then((res) => {
        if (!res.code) {
          this.data_list = res.data.clearing_list;
          this.data_list.map((item) => {
            return (item["isloading"] = false);
          });
          this.loading = false;
          console.log(this.data_list);
        }
      });
    },
    // // // 时间
    // 格式化时间
    formatTime() {
      let date = new Date();
      this.isyear = date.getFullYear();
      this.ismonth = date.getMonth() + 1;
      this.isday = date.getDate();
    },
    // 日历表
    current_time_() {
      let dateTime, this_month, Last_month, next_month;
      let yy = this.isyear;
      let mm = this.ismonth;
      let dd = this.isday;
      dateTime = [`${yy}-${mm < 10 ? "0" + mm : mm}-${dd}`];
      this_month = this.obtain_time_(yy, mm);
      Last_month = this.obtain_time_(
        mm != 1 ? yy : yy - 1,
        mm != 1 ? mm - 1 : 12
      );
      next_month = this.obtain_time_(
        mm != 12 ? yy : yy + 1,
        mm != 12 ? mm + 1 : 1
      );
      console.log(Last_month);
      this.this_month = this_month;
      // console.log(Last_month, this_month, next_month);
      console.log(this_month);
      console.log(this.dateTime.length);
      if (this.dateTime.length == 0) {
        this.dateTime = dateTime;
      }
    },
    // 获取 七天数据
    obtain_time_(yy, mm) {
      yy = Number(yy);
      mm = Number(mm);
      let item_list = [
        {
          time: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_children: [],
        },
      ]; // 返回数据
      if (this.Warehouse[`${yy}-${mm}`]) {
        item_list = this.Warehouse[`${yy}-${mm}`];
      } else {
        let dd = this.mGetDate_(yy, mm), // 获取当前月多少天
          last_days = this.days_fun(`${yy}-${mm}-1`), // 本月的第一天是周几
          next_days = this.days_fun(`${yy}-${mm}-${dd}`), // 本月的最后一天是周几
          last_list = [], // 返回数据
          next_list = []; // 返回数据
        item_list[0].item_children = this.item_list_fun(yy, mm, dd); // 获取当前所有时间
        if (last_days != 0) {
          // 第一天不是周日
          last_list = this.last_days_fun(
            mm != 1 ? yy : yy - 1,
            mm != 1 ? mm - 1 : 12,
            last_days
          );
        }
        if (next_days != 6) {
          // 最后一天不是周六
          next_list = this.next_days_fun(
            mm != 12 ? yy : yy + 1,
            mm != 12 ? mm + 1 : 1,
            next_days
          );
        }
        item_list[0].item_children = [
          ...last_list,
          ...item_list[0].item_children,
          ...next_list,
        ];

        this.Warehouse[`${yy}-${mm}`] = item_list;
      }
      return item_list;
    },
    // 当前日历本月数据
    item_list_fun(yy, mm, dd) {
      let item_children = [];
      for (let index = 1; index <= dd; index++) {
        item_children.push({
          item_title: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_text: index,
        });
      }
      return item_children;
    },
    // 当前日历展示第一行数据
    last_days_fun(yy, mm, days) {
      let item_children = [],
        Last_month = this.mGetDate_(yy, mm); // 上一月多少天
      for (let index = 0; index < days; index++) {
        item_children.unshift("");
      }
      // console.log(item_children);
      return item_children;
    },
    // 当前日历展示最后一行数据
    next_days_fun(yy, mm, days) {
      let item_children = [];
      for (let index = 1; index < 7 - days; index++) {
        item_children.push("");
      }
      return item_children;
    },
    // 计算当前日期
    Calculation_date_(yy, mm, dd, num) {
      let mGetDate = this.mGetDate_(yy, mm), // 当前月多少天
        Last_month = this.mGetDate_(
          mm != 1 ? yy : yy - 1,
          mm != 1 ? mm - 1 : 12
        ), // 上一月多少天
        new_mm = false,
        new_dd = false;
      if (num) {
        new_dd = dd + 7 > mGetDate ? true : false;
        dd = new_dd ? dd + 7 - mGetDate : dd + 7;
        if (new_dd) {
          new_mm = mm + 1 > 12 ? true : false;
          mm = new_mm ? 1 : mm + 1;
        }
        yy = new_mm ? yy + 1 : yy;
        // console.log(yy, mm, dd, num)
      } else {
        new_dd = dd - 7 < 1 ? true : false;
        dd = new_dd ? Last_month + (dd - 7) : dd - 7;
        if (new_dd) {
          new_mm = mm - 1 < 1 ? true : false;
          mm = new_mm ? 12 : mm - 1;
        }
        yy = new_mm ? yy - 1 : yy;
      }
      return `${yy}-${mm}-${dd}`;
    },
    // 本月多少天
    mGetDate_(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    // 周几
    days_fun(e) {
      return new Date(e).getDay();
    },
    // 点击日期
    new_catdate(e) {
      if (this.dateTime.indexOf(e.item_title + "-" + e.item_text) < 0) {
        this.dateTime.push(e.item_title + "-" + e.item_text);
        this.c_list(String(this.dateTime));
      } else {
        if (this.dateTime.length <= 1) {
          this.$message.error("至少选一天");
        } else {
          this.dateTime.splice(
            this.dateTime.indexOf(e.item_title + "-" + e.item_text),
            1
          );
          this.c_list(String(this.dateTime));
        }
      }
      // this.dateTime = e.item_title + "-" + e.item_text;
      console.log(this.dateTime);
    },
    // 排班时间点击日期
    timequery(e) {
      this.dateTime = e.item_title + "-" + e.item_text;
    },
    // 上一个月
    preTime() {
      if (this.ismonth == 1) {
        this.isyear--;
        this.ismonth = 12;
      } else {
        this.ismonth--;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
    // 下一个月
    nextTime() {
      if (this.ismonth == 12) {
        this.isyear++;
        this.ismonth = 1;
      } else {
        this.ismonth++;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
  },
  filters: {
    gender(val) {
      return val == 1 ? "男" : "女";
    },
  },
  created() {
    this.formatTime();
    this.current_time_();
    this.c_list(String(this.dateTime));
    sessionStorage.setItem("nav_id", 5);
    // 4客户权限
    this.tr_id = sessionStorage.getItem("tr_id");
  },
};
</script>

<style lang="less" scoped>
.calendar {
  width: 428px;
  margin: 20px auto;
  .timetop {
    display: flex;
    justify-content: center;
    .timetop_title {
      padding: 0 40px;
    }
  }
  .week {
    display: flex;
    flex-wrap: wrap;
    div {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 12%;
      margin: 1%;
    }
  }
  .time_list {
    border-left: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    .itemlist_div {
      box-sizing: border-box;
      text-align: center;
      width: 60px;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      position: relative;
      cursor: pointer;
      .iconfont {
        position: absolute;
        top: 3px;
        right: 3px;
      }
      &:nth-child(-n + 7) {
        border-top: 1px solid #eee;
      }
      .old_day {
        color: #999;
      }
      .old_day:hover {
        cursor: not-allowed;
        border: 3px solid #fff;
      }
      .active_day {
        border: 3px solid #eee;
        background-color: #eee;
      }
      div {
        border: 3px solid #fff;
        line-height: 45px;
      }
      div:hover {
        border: 3px solid #efefef;
      }
      .no_class {
        cursor: default;
      }
      .no_class:hover {
        border: 3px solid #fff;
      }
    }
  }
  .TimeSelect {
    border: 3px solid #ff8201 !important;
    position: relative;
    .iconfont_wrap {
      display: block !important;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #000;
    }
  }
  .iconfont_wrap {
    display: none;
  }
  .oldSelect {
    border: 3px solid #ff8201 !important;
    background-color: #ff8201;
    color: #fff;
  }
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent;
}
::v-deep .el-table .hover-row > td.el-table__cell {
  background-color: transparent !important;
}
::v-deep .el-table tbody tr:hover > td.el-table__cell {
  background-color: transparent !important;
}
.list /deep/ .el-table__row {
  background-color: #faecd8;
}

.list /deep/ .el-table__expanded-cell .el-table__row {
  background-color: #fff !important;
}
</style>