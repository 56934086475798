import {
    require
} from '../utils/http'

//结算管理列表
function wallet_detail(data) {
    return require('GET', '/api/clearing/v1/wallet_detail/', data)
}


export {
    wallet_detail,
}