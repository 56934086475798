<template>
  <div class="nav">
    <el-row class="tac">
      <el-col :span="24">
        <h5 class="nav_title">
          <img src="@/assets/images/main/left_logo.png" alt="" />
        </h5>
        <el-menu
          background-color="#000000"
          text-color="#fff"
          class="el-menu-vertical-demo"
          :default-active="nav_id"
        >
          <template v-for="item in nav_list">
            <el-menu-item
              :index="item.nav_id"
              :key="item.nav_id"
              v-if="item.isshow.indexOf(Number(tr_id)) > -1 && !item.children"
              @click="switch_nav_id(item)"
            >
              <i class="el-icon-menu"></i>
              <span slot="title">{{ item.name }}</span>
            </el-menu-item>

            <el-submenu
              :index="item.nav_id"
              :key="item.nav_id"
              v-else-if="
                item.isshow.indexOf(Number(tr_id)) > -1 &&
                item.children != undefined &&
                item.children.length !== 0
              "
              @click="switch_nav_id(item)"
            >
              <template slot="title"
                ><i class="el-icon-location"></i>
                <span>{{ item.name }}</span>
              </template>
              <template>
                <el-menu-item
                  v-for="list in item.children"
                  :key="list.nav_id"
                  @click="switch_nav_id(list)"
                  :index="list.nav_id"
                  >{{ list.name }}</el-menu-item
                >
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getPath } from "@/utils/nav.js";
export default {
  data() {
    return {
      nav_list: [],
      tr_id: "",
      nav_id: "",
    };
  },
  methods: {
    switch_nav_id(item) {
      sessionStorage.setItem("nav_id", item.nav_id);
      this.nav_id = item.nav_id;
      if (this.$route.path != item.path) {
        this.$router.push({ path: item.path });
      }
      console.log(this.nav_id);
    },
  },
  created() {
    this.nav_id = sessionStorage.getItem("nav_id");
    this.nav_list = getPath();
    this.tr_id = sessionStorage.getItem("tr_id");
    this.nav_list.map((item) => {
      if (item.path == this.$route.path) {
        this.nav_id = item.nav_id;
      } else {
        if (item.children) {
          item.children.map((list) => {
            if (list.path == this.$route.path) {
              this.nav_id = list.nav_id;
            }
          });
        }
      }
    });
  },
};
</script>


<style lang="less" scoped>
.nav {
  width: 180px;
  background: #000000;
  height: 100vh;
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   z-index: 200;
  //   overflow: auto;
  .nav_title {
    color: #fff;
    font-size: 24px;
    text-align: center;
    margin: 16px 0;
  }
}
.nav /deep/ .el-menu {
  border-right: none;
}
.el-submenu .el-menu-item {
  min-width: auto;
}
</style>