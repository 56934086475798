<template>
  <div class="wrap">
    <Nav></Nav>
    <div class="main_wrap">
      <Header></Header>
      <div class="main">
        <div class="content">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.wrap {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #efeff0;
}
.main_wrap {
  width: calc(100% - 180px);
}
.main {
  height: calc(100% - 61px);
  overflow: auto;
}
.content {
  min-width: 1280px;
  background-color: #fff;
  margin: 10px 60px 40px;
  padding: 20px;
}
.main::-webkit-scrollbar {
  width: 0; /*对垂直流动条有效*/
  height: 6px; /*对水平流动条有效*/
  // width: 0 !important;
}
.main::-webkit-scrollbar-track {
  background-color: #E4E7ED;
  border-radius: 5px;
  }
.main::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #FF8201;
}

// .main {
//   height: 5000px;
//   background-color: #e9eef3;
//   width: calc(100% - 180px);
//   position: relative;
//   left: 180px;
//   top: 73px;
// }
</style>
    
<script>
import Nav from "@/components/nav";
import Header from "@/components/header";
export default {
  data() {
    return {};
  },
  components: {
    Nav,
    Header,
  },
  created() {},
};
</script>