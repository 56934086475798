<template>
  <div v-loading="loading">
    <el-button size="mini" @click="onScreen">筛选</el-button>
    <el-dialog
      title="筛选条件"
      :visible.sync="screen"
      class="class_dialogTableVisible"
      @close="dialogClose"
    >
      <el-form class="staff_form" style="max-height: 60vh; overflow: auto">
        <el-form-item class="el_form_item" label="沟通状态">
          <el-select
            class="el_input"
            v-model="screen_list.status"
            placeholder="请选择沟通状态"
          >
            <el-option
              v-for="item in followstatus"
              :key="item.status"
              :label="item.value"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="el_form_item" label="性别">
          <el-select
            class="el_input"
            v-model="screen_list.gender"
            placeholder="请选择性别"
          >
            <el-option
              v-for="item in gender_id"
              :key="item.status"
              :label="item.value"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="el_form_item" label="年龄">
          <div class="el_input flex_el_input">
            <el-input
              type="text"
              class="age_fanwei"
              clearable
              v-model="screen_list.age_min"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              placeholder="年纪范围最小值"
              maxlength="2"
            ></el-input>
            <span style="margin: 0 3.2%">——</span>
            <el-input
              type="text"
              class="age_fanwei"
              clearable
              v-model="screen_list.age_max"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              placeholder="年纪范围最大值"
              maxlength="2"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item class="el_form_item" label="地区">
          <el-cascader
            v-model="screen_list.province"
            :options="staff_form_city"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="button_footer">
        <div>
          <el-button @click="screen_list = {}" type="warning">清空所有筛选条件</el-button>
        </div>
        <div>
          <el-button @click="screen = false">取 消</el-button>
          <el-button type="primary" @click="screen_queding">确 定</el-button>
        </div>
      </div>
    </el-dialog>
    <el-table :data="data_list" style="width: 100%">
      <el-table-column prop="name" label="姓名" width="120">
        <template slot-scope="scope">
          <span
            @click="details(scope.row)"
            style="color: #409eff; cursor: pointer"
            >{{ scope.row.name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="tags_list" label="标签" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.tags_list">
            <el-tag
              style="margin-right: 5px"
              v-for="(item, index) in scope.row.tags_list.slice(0, 2)"
              :key="index"
              size="mini"
              >{{ item | ellipsis }}</el-tag
            >
            <span v-if="scope.row.tags_list.length > 2">...</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-popover placement="bottom" trigger="hover">
            <el-tag
              v-for="(item, index) in scope.row.tags_list"
              :key="index"
              disable-transitions
              size="mini"
              style="margin: 2px"
              >{{ item }}</el-tag
            >
            <div slot="reference" class="more">
              <el-tag
                v-for="(item, index) in scope.row.tags_list.slice(0, 2)"
                :key="index"
                disable-transitions
                size="mini"
                style="margin: 2px"
                >{{ item | ellipsis }}</el-tag
              >
              <span v-if="scope.row.tags_list.length > 2">...</span>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="沟通状态" width="120">
        <template slot-scope="scope">{{
          scope.row.status | statusfilter
        }}</template>
      </el-table-column>
      <el-table-column prop="gender" label="性别" width="100">
      </el-table-column>
      <el-table-column prop="age" label="年龄" width="100"> </el-table-column>
      <el-table-column prop="phone" label="联系方式" width="200">
      </el-table-column>

      <el-table-column prop="place" label="期望地区" width="190">
        <template slot-scope="scope">
          {{ scope.row.place == "" ? "未填写" : scope.row.place }}
        </template>
      </el-table-column>
      <el-table-column prop="work_time" label="到岗时间" width="150">
      </el-table-column>
      <el-table-column label="更新时间" width="200">
        <template slot-scope="scope">{{
          scope.row.create_time | create_timeFilters
        }}</template>
      </el-table-column>
      <!-- <el-table-column>
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="details(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <div style="margin-top: 20px; display: flex; justify-content: center">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="radar_count"
        :page-size="20"
        :current-page="page"
        @current-change="Screen_pagination"
      >
      </el-pagination>
    </div>

    <el-drawer
      @close="handleClick"
      v-loading="vloading"
      title="我是标题"
      size="60%"
      :visible.sync="drawer"
      :with-header="false"
    >
      <div class="drawer" v-if="JSON.stringify(this.radar_detail) != '{}'">
        <div class="left">
          <div class="title">
            <p>详情</p>
            <el-button type="primary" class="bj" @click="edit">{{
              !isedit ? "编辑" : "保存"
            }}</el-button>
          </div>
          <el-form label="left" label-position="left">
            <el-form-item label="姓名：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.name }}
              </div>
              <el-input v-else v-model="Echo.resume_name"></el-input>
            </el-form-item>
            <el-form-item label="性别：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.gender }}
              </div>
              <el-radio-group v-else v-model="Echo.weixin_gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.age }}
              </div>
              <el-input v-else v-model="Echo.resume_age"></el-input>
            </el-form-item>
            <el-form-item label="联系方式：">
              <div>
                {{ radar_detail.elite_info.phone }}
              </div>
            </el-form-item>
            <el-form-item label="期望地区：">
              <div v-if="!isedit">
                {{
                  radar_detail.elite_info.place
                    ? radar_detail.elite_info.place
                    : "暂无"
                }}
              </div>
              <el-cascader
                v-else
                v-model="Echo.weixin_province"
                :options="data_place"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址：">
              <div v-if="!isedit">
                {{
                  radar_detail.elite_info.place_detail
                    ? radar_detail.elite_info.place_detail
                    : "暂无"
                }}
              </div>
              <el-input v-else v-model="Echo.weixin_city"></el-input>
            </el-form-item>
            <el-form-item label="到岗时间：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.work_time_str }}
              </div>
              <div v-else>
                <el-date-picker
                  format="yyyy 年 MM 月 dd 日"
                  value-format="timestamp"
                  v-model="Echo.work_time"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="沟通状态：">
              <el-select
                v-model="followvalue"
                placeholder="请选择"
                @change="switchfollowvalue"
              >
                <el-option
                  v-for="item in followstatus"
                  :key="item.status"
                  :label="item.value"
                  :value="item.status"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="tags">
            <div class="tags_title">个人标签</div>
            <div v-if="radar_detail.elite_info.resume_tags_list.length != 0">
              <el-tag
                type="success"
                v-for="(item, index) in radar_detail.elite_info
                  .resume_tags_list"
                :key="index"
                >{{ item }}</el-tag
              >
            </div>
            <div v-else>
              <el-tag type="success">暂无标签</el-tag>
            </div>
          </div>

          <div class="tags">
            <div class="tags_title">添加标签(1-6个，每条最多20字)</div>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              maxlength="20"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >添加标签</el-button
            >
          </div>

          <!-- <div class="tags ispingjia">
            <div class="tags_title">评价</div>
            <div class="ispingjia_cont_wrap">
              <el-tag
                type="success"
                v-for="(item, index) in evaluate_list"
                :key="index"
                style="display: block; height: auto; line-height: 24px"
                ><div>内容：{{ item.eval }}</div>
                <div style="display: flex; justify-content: flex-end">
                  时间：{{ item.create_time }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 评价人：{{ item.name }}
                </div></el-tag
              >
            </div>
          </div> -->
          <div class="Timeline">
            <div class="Timeline_title">
              <div>零工浏览 / 报名痕迹</div>
              <div>
                <el-button type="primary" @click="SchedulingDrawerFn()"
                  >查看全部排班</el-button
                >
                <el-button type="success" @click="push_Oddjob"
                  >进行排班</el-button
                >
              </div>
            </div>
            <div
              class="info"
              v-for="(item, index) in radar_detail.radar_list"
              :key="index"
            >
              <div>浏览公司：{{ item.company_name }}</div>
              <div>浏览岗位：{{ !item.jd_name ? "暂无" : item.jd_name }}</div>
              <div class="time">
                <div>
                  工作时间：{{ !item.work_hour ? "暂无" : item.work_hour }}
                </div>
                <div>
                  浏览时间：{{ !item.create_time ? "暂无" : item.create_time }}
                </div>
              </div>
              <div class="img" v-if="item.is_send == 1">
                <img src="~@/assets/images/main/yibaoming.png" alt="" />
              </div>
              <div class="Job_info">
                <div v-if="item.is_odd == 0">
                  <img src="~@/assets/images/main/is_odd0.png" alt="" />
                </div>
                <div v-if="item.is_odd == 2">
                  <img src="~@/assets/images/main/is_odd2.png" alt="" />
                </div>
                <div v-if="item.is_odd == 3">
                  <img src="~@/assets/images/main/is_odd3.png" alt="" />
                </div>
                <div v-if="item.is_odd == 4">
                  <img src="~@/assets/images/main/is_odd4.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <p>跟进记录</p>
          </div>
          <div class="right_input">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="recordInput"
            >
            </el-input>
            <div style="display: flex; justify-content: flex-end">
              <el-button
                style="padding: 6px 20px; margin-top: 10px"
                @click="submit_record"
                type="primary"
                >提交</el-button
              >
            </div>
          </div>
          <div class="recorList">
            <el-timeline :reverse="false">
              <el-timeline-item
                v-for="(item, index) in recordList"
                :key="index"
                :timestamp="item.create_time"
              >
                {{ item.records }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <!-- 推送 -->
      <el-drawer
        title="推送"
        :append-to-body="true"
        :before-close="two_handleClose"
        :visible.sync="innerDrawer"
        size="50%"
      >
        <div v-loading="vvloading">
          <div class="push">
            <div class="choice_project_list">
              <div class="choice_wrap">
                <div class="left_title">所有职位：</div>
                <el-select
                  @change="select_project_list"
                  v-model="project_list_value"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in project_list"
                    :key="item.user_detail_id"
                    :label="item.company_name"
                    :value="item.user_detail_id"
                  >
                  </el-option>
                </el-select>
              </div>
              <el-radio-group v-model="Select_jd_list">
                <div v-for="item in jd_list" :key="item.jd_id">
                  <el-radio @change="choice(item)" :label="item"
                    >{{ item.jd_title }} | 工作时段：{{ item.work_hour }}
                  </el-radio>
                  <div></div>
                </div>
              </el-radio-group>
            </div>
            <div class="calendar" v-if="JSON.stringify(this_month) != '{}'">
              <div class="timetop">
                <div @click="preTime" style="cursor: pointer">
                  <i class="iconfont icon-shangyiye"></i>
                </div>
                <div class="timetop_title">{{ this_month[0].time }}</div>
                <div @click="nextTime" style="cursor: pointer">
                  <i class="iconfont icon-xiayiye"></i>
                </div>
              </div>
              <div class="week">
                <div v-for="(item, index) in week" :key="index">{{ item }}</div>
              </div>
              <div class="onlist_">
                <div
                  class="time_list"
                  v-if="Select_jd_list != '' || Select_jd_list.length != 0"
                >
                  <div
                    class="itemlist_div"
                    v-for="(item, index) in this_month[0].item_children"
                    :key="index"
                  >
                    <div
                      class="old_day"
                      v-if="
                        Date.parse(new Date()) - 86400000 >
                        Date.parse(item.item_title + '-' + item.item_text)
                      "
                    >
                      {{ item.item_text }}
                    </div>
                    <div
                      v-if="
                        item != '' &&
                        Date.parse(new Date()) - 86400000 <
                          Date.parse(item.item_title + '-' + item.item_text) &&
                        this_time.indexOf(
                          item.item_title + '-' + item.item_text
                        ) != -1 &&
                        Select_jd_list.work_type == 1
                      "
                      @click="new_catdate(item)"
                      :class="{
                        TimeSelect:
                          dateTime.indexOf(
                            item.item_title + '-' + item.item_text
                          ) != -1,
                        oldSelect:
                          Object.keys(SchedulingTime).indexOf(
                            item.item_title + '-' + item.item_text
                          ) != -1,
                      }"
                    >
                      {{ item.item_text }}
                    </div>
                    <div
                      class="old_day"
                      v-if="
                        Date.parse(new Date()) - 86400000 <
                          Date.parse(item.item_title + '-' + item.item_text) &&
                        this_time.indexOf(
                          item.item_title + '-' + item.item_text
                        ) == -1 &&
                        Select_jd_list.work_type == 1
                      "
                    >
                      {{ item.item_text }}
                    </div>

                    <div
                      v-if="
                        item != '' &&
                        Date.parse(new Date()) - 86400000 <
                          Date.parse(item.item_title + '-' + item.item_text) &&
                        Select_jd_list.work_type == 0
                      "
                      @click="new_catdate(item)"
                      :class="{
                        TimeSelect:
                          dateTime.indexOf(
                            item.item_title + '-' + item.item_text
                          ) != -1,
                        oldSelect:
                          Object.keys(SchedulingTime).indexOf(
                            item.item_title + '-' + item.item_text
                          ) != -1,
                      }"
                    >
                      {{ item.item_text }}
                    </div>
                    <!-- <div v-else class="no_class"></div> -->
                  </div>
                </div>
                <div class="time_list" v-else>
                  <div
                    class="itemlist_div"
                    v-for="(item, index) in this_month[0].item_children"
                    :key="index"
                  >
                    <div class="old_day">
                      {{ item.item_text }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="calendar_time_list">
                <div v-for="item in push_list" :key="item.jd_id">
                  {{ item.arrange_work | push_list_filters }} |
                  {{ item.company_name }} | {{ item.jd_title }}
                  <i
                    class="iconfont icon-guanbi"
                    @click="close_pushlist(item)"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="end_Scheduling">
            <el-button type="success" @click="continues" class=""
              >继续排班</el-button
            >
            <el-button type="primary" @click="onSubmit" class=""
              >确认排班</el-button
            >
          </div>
        </div>
      </el-drawer>
      <!-- 零工日历 -->
      <el-drawer
        title="零工排班日历"
        :append-to-body="true"
        :before-close="three_handleClose"
        :visible.sync="SchedulingDrawer"
        size="40%"
      >
        <div
          v-loading="vvloading"
          class="calendar All_calendars"
          v-if="JSON.stringify(this_month) != '{}'"
        >
          <div class="timetop">
            <div @click="preTime" style="cursor: pointer">
              <i class="iconfont icon-shangyiye"></i>
            </div>
            <div class="timetop_title">{{ this_month[0].time }}</div>
            <div @click="nextTime" style="cursor: pointer">
              <i class="iconfont icon-xiayiye"></i>
            </div>
          </div>
          <div class="week">
            <div v-for="(item, index) in week" :key="index">{{ item }}</div>
          </div>
          <div>
            <div class="time_list">
              <div
                class="itemlist_div"
                v-for="(item, index) in this_month[0].item_children"
                :key="index"
              >
                <div
                  v-if="
                    Object.keys(isOddSchedulingList).indexOf(
                      item.item_title + '-' + item.item_text
                    ) != -1
                  "
                  @click="timequery(item)"
                  :class="{
                    TimeSelect:
                      dateTime == item.item_title + '-' + item.item_text,
                  }"
                >
                  {{ item.item_text }}
                  <p class="iconfont_wrap"></p>
                </div>
                <div class="old_day" v-else>
                  {{ item.item_text }}
                </div>
                <!-- <i
                  class="iconfont icon-duihao"
                  id="duihao"
                  v-if="dateTime == item.item_title + '-' + item.item_text"
                ></i> -->
              </div>
            </div>
          </div>
        </div>
        <div
          style="margin: 20px auto 0; width: 80%"
          v-if="isOddSchedulingList[this.dateTime] != undefined"
        >
          <div
            class="add_time_list_authentication"
            v-for="(list, dex) in isOddSchedulingList[this.dateTime]"
            :key="dex"
            v-html="OddSchedulingFilter(list)"
          >
            {{ list }}
          </div>
        </div>
        <el-empty v-else description="请选择时间查看排班"></el-empty>
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import Vue from "vue";
// 零工排班日历列表内容过滤
Vue.prototype.OddSchedulingFilter = function (value) {
  return `<div style="width:65%;">公司名称：${value.company}</div><div style="width:35%;">职位：${value.jd_name}</div><div style="width:65%;">品牌名：${value.company_short_name}</div><div style="width:35%;">工作时间：${value.work_up}-${value.work_down}</div>`;
};
import {
  radar_list,
  radar_detail,
  set_tags,
  get_place,
  update_odd_job,
  all_jd_list,
  push_odd_job,
  get_rs_jd,
  set_status,
  set_record,
  get_record,
  get_odd_all_wt,
  getset_eval,
  show_client_place,
} from "@/api/radar_cue";
import { p_list, jd_list } from "@/api/project";
export default {
  data() {
    return {
      screen: false, //筛选显示隐藏
      loading: true,
      vloading: false,
      vvloading: true,
      //  列表
      data_list: [],
      // 总条数
      radar_count: 0,
      //   页码
      page: 1,
      // 筛选的内容
      screen_list: {},
      new_screen_list: {},
      // 员工ID
      elite_id: "",
      jd_id: "", //查看零工 工作排班时间传的职位ID
      isOddSchedulingList: {},
      //   抽屉显示
      drawer: false,
      // 跟进状态
      followvalue: "0",
      followstatus: [
        {
          status: "0",
          value: "未拨打",
        },
        {
          status: "1",
          value: "已接通",
        },
        {
          status: "2",
          value: "未接通",
        },
        {
          status: "3",
          value: "号码无效",
        },
        {
          status: "4",
          value: "不合适",
        },
      ],
      gender_id: [
        {
          status: "0",
          value: "男女不限",
        },
        {
          status: "1",
          value: "男",
        },
        {
          status: "2",
          value: "女",
        },
      ],
      staff_form_city: [], //筛选地点地点
      // 跟进记录
      recordInput: "",
      recordList: [],
      // 评价列表
      evaluate_list: [],
      // 二级抽屉显示
      innerDrawer: false,
      // 零工排班抽屉显示
      SchedulingDrawer: false,
      // 线索详情
      radar_detail: {},
      // tag标签
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      // 是否为编辑
      isedit: false,
      edit_list: [],
      // 回显的数据
      Echo: {},
      // 地点列表
      data_place: {},
      // 可推送职位
      // 已报名
      send_list: [],
      // 已浏览
      radar_list: [],
      Select_jd_list: "",
      user_detail_id: "",
      jd_list: [],
      // 客服的项目列表
      project_list: [],
      project_list_value: "",
      // 选中职位的招工月日
      this_time: [],
      //当前年月日
      isyear: "",
      ismonth: "",
      isday: "",

      // 排班的时间
      SchedulingTime: {},
      // 旧的
      week: ["日", "一", "二", "三", "四", "五", "六"],
      time_frame: "", // 时间范围
      days: "", // 周的下标
      dateTime: [], // 日期时间
      // 新的
      Warehouse: {}, // 仓库
      this_month: {}, // 当前月份
      item_children: [],

      // 需要提交的排班值
      push_list: {},
    };
  },
  filters: {
    // 更新时间过滤
    create_timeFilters(val) {
      return val.split(" ")[0];
    },
    //所有职位下单选框内容筛选
    push_list_filters(value) {
      function formatDayTime(val) {
        if (val) {
          let date = new Date(val);
          let Y = date.getFullYear();
          let M = date.getMonth() + 1;
          let D = date.getDate();

          if (M < 10) {
            M = "0" + M;
          }
          if (D < 10) {
            D = "0" + D;
          }

          return Y + "年" + M + "月" + D + "日";
        } else {
          return "";
        }
      }
      //
      var list = [];
      let new_value = value.toString().split(",");
      console.log(new_value);
      new_value.map((item) => {
        list.push(formatDayTime(item.split("-")[0] * 1000));
      });
      return list.toString();
    },
    // 列表沟通状态过滤
    statusfilter(value) {
      switch (value) {
        case 0:
          return `未拨打`;
          break;
        case 1:
          return `已接通`;
          break;
        case 2:
          return `未接通`;
          break;
        case 3:
          return `号码无效`;
          break;
        case 4:
          return `不合适`;
          break;
      }
      return value;
    },
    // 标签多余4个字的过滤
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 6) {
        return value.slice(0, 6) + "...";
      }
      return value;
    },
    // 添加的时间过滤
    push_date(value) {
      let times = value.split("-");
      var a = formatDate(times[0] * 1000);
      var b = formatDate(times[1] * 1000);
      function formatDate(date) {
        var date = new Date(date);
        var YY = date.getFullYear() + "-";
        var MM =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-";
        var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        var hh =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":";
        var mm =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":";
        var ss =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        return YY + MM + DD + " " + hh + mm + ss;
      }
      return a + " —— " + b;
    },
  },
  methods: {
    // 点击筛选
    onScreen() {
      this.screen = true;
      if (JSON.stringify(this.new_screen_list) == "{}") {
        return;
      } else {
        this.screen_list = JSON.parse(JSON.stringify(this.new_screen_list));
      }
    },
    //确定筛选
    screen_queding() {
      this.new_screen_list = JSON.parse(JSON.stringify(this.screen_list));
      this.screen_list = {};
      this.page = 1;
      this.screen = false;
      this.get_list();
    },
    // 关闭时执行
    dialogClose() {
      console.log("关闭了");
      this.screen_list = {};
    },

    // 点击排班日历显示
    SchedulingDrawerFn() {
      this.SchedulingDrawer = true;
      this.formatTime();
      this.current_time_();
      this.get_odd_all_wt();
    },
    // 查看零工 工作排班时间
    get_odd_all_wt() {
      // this.vvloading = true;
      var obj = {
        elite_id: this.elite_id,
        year: this.isyear,
        month: this.ismonth,
      };
      get_odd_all_wt(obj).then((res) => {
        if (!res.code) {
          this.isOddSchedulingList = res.data.arrange_work;
          this.vvloading = false;
          console.log(this.isOddSchedulingList);
        }
      });
    },
    // 点击推送
    push_submit() {},
    // 选择时执行
    choice(item) {
      console.log(this.Select_jd_list);
      this.this_time = item.work_day.split(",");
      this.dateTime = [];
      console.log(this.push_list[item.jd_id]);
      if (this.push_list[item.jd_id] == undefined) {
        this.dateTime = [];
      } else {
        this.push_list[item.jd_id].arrange_work.map((item) => {
          this.dateTime.push(this.formatDayTime(item.split("-")[0] * 1000));
        });
      }
    },
    //yyyy-mm-dd格式日期
    formatDayTime(val) {
      if (val) {
        let date = new Date(val);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();

        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }

        return Y + "-" + M + "-" + D;
      } else {
        return "";
      }
    },

    // 抽屉关闭执行
    handleClick() {
      this.get_list();
      this.radar_detail = {};
      this.isedit = false;
    },
    // 二级抽屉关闭执行
    two_handleClose(done) {
      done();
      this.no();
    },
    //
    no() {
      this.jd_list = [];
      this.project_list_value = "";
      this.project_list = [];

      // 选择的日期
      this.dateTime = [];
      this.Select_jd_list = [];
      this.push_list = {};
      this.push_data = {};
    },
    // 零工排班详情抽屉
    three_handleClose(done) {
      done();
      this.dateTime = [];
    },
    // 推送
    push_Oddjob() {
      this.formatTime();
      this.current_time_();
      this.p_list();
      this.innerDrawer = true;
      this.isedit = false;
      this.vvloading = false;
    },
    // 获取我的项目列表
    p_list() {
      p_list().then((res) => {
        if (!res.code) {
          this.project_list = res.data.project_list;
          console.log(this.project_list);
        }
      });
    },
    // 选择项目执行
    select_project_list() {
      (this.dateTime = []),
        jd_list({ project_id: this.project_list_value }).then((res) => {
          if (!res.code) {
            this.jd_list = res.data.jd_list;
            this.jd_list.map((item) => {
              // item["allId"] = item.jd_id + "," + item.work_hour;
            });
            console.log(this.jd_list);
          }
        });
    },
    // 地点选中
    handleChange(value) {
      console.log(value);
    },
    // 编辑
    edit() {
      if (!this.isedit) {
        //回显
        this.Echo = {
          resume_phone: this.radar_detail.elite_info.phone,
          resume_name: this.radar_detail.elite_info.name,
          resume_age: this.radar_detail.elite_info.age,
          weixin_gender: this.radar_detail.elite_info.gender == "男" ? 1 : 2,
          weixin_province: this.radar_detail.elite_info.place_id_list,
          work_time:
            this.radar_detail.elite_info.work_time != 0
              ? this.radar_detail.elite_info.work_time * 1000
              : "",
          weixin_city: this.radar_detail.elite_info.place_detail,
        };
        console.log(this.Echo);
        this.isedit = true;
      } else {
        this.vloading = true;
        var obj = {
          elite_id: this.elite_id,
          odd_job_data: {
            resume_phone: this.Echo.resume_phone,
            resume_name: this.Echo.resume_name,
            resume_age: this.Echo.resume_age,
            weixin_gender: this.Echo.weixin_gender,
            weixin_province: this.Echo.weixin_province[2],
            work_time: this.Echo.work_time / 1000,
            weixin_city: this.Echo.weixin_city,
          },
        };
        update_odd_job(obj).then((res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.isedit = false;
            this.radar_detailFn();
          }
        });
        console.log(obj);
      }
    },
    // tag标签
    handleClose(tag) {
      // 删除tag标签
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      var arrJoinString = this.dynamicTags.join();
      set_tags({ elite_id: this.elite_id, tags: arrJoinString }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点击添加的时候执行
    showInput() {
      if (this.dynamicTags != undefined && this.dynamicTags.length >= 6) {
        this.$message({
          message: "职位技能只能添加6个",
          type: "warning",
        });
      } else {
        this.inputVisible = true;
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      }
    },
    // 确认添加的时候执行
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        console.log(this);
        this.dynamicTags.push(inputValue);
        var arrJoinString = this.dynamicTags.join();
        set_tags({ elite_id: this.elite_id, tags: arrJoinString }).then(
          (res) => {
            if (!res.code) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          }
        );
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 翻页
    Screen_pagination(val) {
      this.page = val;
      this.get_list();
    },
    // 获取列表
    get_list() {
      this.loading = true;
      var obj = {
        page: this.page - 1,
        status: this.new_screen_list.status ? this.new_screen_list.status : "",
        gender: this.new_screen_list.gender ? this.new_screen_list.gender : "",
        min_age: this.new_screen_list.age_min
          ? this.new_screen_list.age_min
          : "",
        max_age: this.new_screen_list.age_max
          ? this.new_screen_list.age_max
          : "",
        place_id: this.new_screen_list.province
          ? this.new_screen_list.province[2]
          : "",
      };
      radar_list(obj).then((res) => {
        if (!res.code) {
          this.data_list = res.data.radar_list;
          this.radar_count = res.data.radar_count;
          this.loading = false;
        }
      });
    },
    // 查看零工评价
    getset_eval() {
      this.loading = true;
      getset_eval({ elite_id: this.elite_id }).then((res) => {
        if (!res.code) {
          this.evaluate_list = res.data.eval_data;
          this.loading = false;
        }
      });
    },
    details(row) {
      // row 数据
      this.drawer = true;
      //   调取列表接口详情
      this.vloading = true;
      // 赋值ID
      this.elite_id = row.elite_id;
      this.getset_eval();
      this.radar_detailFn();
      this.get_record();
    },
    // 雷达线索详情接口调用
    radar_detailFn() {
      radar_detail({ elite_id: this.elite_id }).then((res) => {
        if (!res.code) {
          this.vloading = false;
          this.radar_detail = res.data;
          this.followvalue = String(res.data.elite_info.status);
          console.log(this.followvalue);
          if (res.data.elite_info.tags_list != undefined) {
            this.dynamicTags = res.data.elite_info.tags_list;
          } else {
            this.dynamicTags = [];
          }
          // console.log(this.dynamicTags);
          // console.log(this.radar_detail);
        }
      });
    },
    // 跟进状态切换
    switchfollowvalue() {
      set_status({ elite_id: this.elite_id, status: this.followvalue }).then(
        (res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.get_record();
          }
        }
      );
    },
    // 添加跟进记录
    submit_record() {
      var new_change = this.recordInput.replace(/\s*/g, "");
      if (new_change == "") {
        this.$message.error("请输入跟进记录");
        return;
      }
      set_record({ elite_id: this.elite_id, record: this.recordInput }).then(
        (res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.recordInput = "";
            this.get_record();
          }
        }
      );
    },
    // 查询跟进记录
    get_record() {
      get_record({ elite_id: this.elite_id }).then((res) => {
        if (!res.code) {
          this.recordList = res.data.records_list;
          console.log(this.recordList);
        }
      });
    },
    // 获取地区列表
    get_place() {
      get_place({ unlimited: 0 }).then((res) => {
        if (!res.code) {
          this.data_place = res.data;
          // console.log(this.data_place);
        }
      });
    },

    // // // 时间
    // 格式化时间
    formatTime() {
      let date = new Date();
      this.isyear = date.getFullYear();
      this.ismonth = date.getMonth() + 1;
      this.isday = date.getDate();
    },
    // 日历表
    current_time_() {
      let dateTime, this_month, Last_month, next_month;
      let yy = this.isyear;
      let mm = this.ismonth;
      let dd = this.isday;
      dateTime = [`${yy}-${mm < 10 ? "0" + mm : mm}-${dd}`];
      this_month = this.obtain_time_(yy, mm);
      Last_month = this.obtain_time_(
        mm != 1 ? yy : yy - 1,
        mm != 1 ? mm - 1 : 12
      );
      next_month = this.obtain_time_(
        mm != 12 ? yy : yy + 1,
        mm != 12 ? mm + 1 : 1
      );
      console.log(Last_month);
      this.this_month = this_month;
      // console.log(Last_month, this_month, next_month);
      console.log(this_month);
      console.log(this.dateTime.length);
      if (this.dateTime.length == 0) {
        this.dateTime = dateTime;
      }
    },
    // 获取 七天数据
    obtain_time_(yy, mm) {
      yy = Number(yy);
      mm = Number(mm);
      let item_list = [
        {
          time: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_children: [],
        },
      ]; // 返回数据
      if (this.Warehouse[`${yy}-${mm}`]) {
        item_list = this.Warehouse[`${yy}-${mm}`];
      } else {
        let dd = this.mGetDate_(yy, mm), // 获取当前月多少天
          last_days = this.days_fun(`${yy}-${mm}-1`), // 本月的第一天是周几
          next_days = this.days_fun(`${yy}-${mm}-${dd}`), // 本月的最后一天是周几
          last_list = [], // 返回数据
          next_list = []; // 返回数据
        item_list[0].item_children = this.item_list_fun(yy, mm, dd); // 获取当前所有时间
        if (last_days != 0) {
          // 第一天不是周日
          last_list = this.last_days_fun(
            mm != 1 ? yy : yy - 1,
            mm != 1 ? mm - 1 : 12,
            last_days
          );
        }
        if (next_days != 6) {
          // 最后一天不是周六
          next_list = this.next_days_fun(
            mm != 12 ? yy : yy + 1,
            mm != 12 ? mm + 1 : 1,
            next_days
          );
        }
        item_list[0].item_children = [
          ...last_list,
          ...item_list[0].item_children,
          ...next_list,
        ];

        this.Warehouse[`${yy}-${mm}`] = item_list;
      }
      return item_list;
    },
    // 当前日历本月数据
    item_list_fun(yy, mm, dd) {
      let item_children = [];
      for (let index = 1; index <= dd; index++) {
        item_children.push({
          item_title: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_text: index,
        });
      }
      return item_children;
    },
    // 当前日历展示第一行数据
    last_days_fun(yy, mm, days) {
      let item_children = [],
        Last_month = this.mGetDate_(yy, mm); // 上一月多少天
      for (let index = 0; index < days; index++) {
        item_children.unshift("");
      }
      // console.log(item_children);
      return item_children;
    },
    // 当前日历展示最后一行数据
    next_days_fun(yy, mm, days) {
      let item_children = [];
      for (let index = 1; index < 7 - days; index++) {
        item_children.push("");
      }
      return item_children;
    },
    // 计算当前日期
    Calculation_date_(yy, mm, dd, num) {
      let mGetDate = this.mGetDate_(yy, mm), // 当前月多少天
        Last_month = this.mGetDate_(
          mm != 1 ? yy : yy - 1,
          mm != 1 ? mm - 1 : 12
        ), // 上一月多少天
        new_mm = false,
        new_dd = false;
      if (num) {
        new_dd = dd + 7 > mGetDate ? true : false;
        dd = new_dd ? dd + 7 - mGetDate : dd + 7;
        if (new_dd) {
          new_mm = mm + 1 > 12 ? true : false;
          mm = new_mm ? 1 : mm + 1;
        }
        yy = new_mm ? yy + 1 : yy;
        // console.log(yy, mm, dd, num)
      } else {
        new_dd = dd - 7 < 1 ? true : false;
        dd = new_dd ? Last_month + (dd - 7) : dd - 7;
        if (new_dd) {
          new_mm = mm - 1 < 1 ? true : false;
          mm = new_mm ? 12 : mm - 1;
        }
        yy = new_mm ? yy - 1 : yy;
      }
      return `${yy}-${mm}-${dd}`;
    },
    // 本月多少天
    mGetDate_(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    // 周几
    days_fun(e) {
      return new Date(e).getDay();
    },
    // 选择日期显示职位列表
    all_jd_list() {
      this.vvloading = true;
      all_jd_list({ select_day: String(this.dateTime) }).then((res) => {
        if (!res.code) {
          this.jd_list = res.data.jd_list;
          this.jd_list.map((item) => {
            console.log(item);
            item["allId"] = item.jd_id + "," + item.work_hour;
          });
          console.log(res);
          this.vvloading = false;
        }
      });
    },
    // 点击日期
    new_catdate(e) {
      if (this.dateTime.indexOf(e.item_title + "-" + e.item_text) < 0) {
        if (this.dateTime.length >= 7) {
          this.$message.error("最多只能选七天");
        } else {
          this.dateTime.push(e.item_title + "-" + e.item_text);
          console.log(String(this.dateTime));
        }
      } else {
        this.dateTime.splice(
          this.dateTime.indexOf(e.item_title + "-" + e.item_text),
          1
        );
      }
      // console.log(this.dateTime);
    },
    // 数组去重
    unique(arr) {
      var newArr = [];
      for (var i = 0; i < arr.length; i++) {
        if (newArr.indexOf(arr[i]) === -1) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    // 排班时间点击日期
    timequery(e) {
      this.dateTime = e.item_title + "-" + e.item_text;
    },

    // 继续排班
    continues() {
      if (this.Select_jd_list == "" || this.Select_jd_list.length == 0) {
        this.$message.error("请选择职位");
        return;
      }
      var new_bork_hour = [];
      var work_hour_data = this.Select_jd_list.work_hour.split("-");
      this.dateTime.map((item) => {
        new_bork_hour.push(
          Date.parse(item + " " + work_hour_data[0]) / 1000 +
            "-" +
            Date.parse(item + " " + work_hour_data[1]) / 1000
        );
      });
      if (this.Select_jd_list == "") {
        this.$message.error("请选择职位");
        return;
      }
      if (this.dateTime.length == 0) {
        delete this.push_list[this.Select_jd_list.jd_id];
        this.$forceUpdate();
        console.log(this.push);
        // this.$message.error("至少选择一天");
        return;
      }
      this.push_list[this.Select_jd_list.jd_id] = {
        company_name: this.Select_jd_list.company_name,
        jd_title: this.Select_jd_list.jd_title,
        jd_id: this.Select_jd_list.jd_id,
        project_id: this.Select_jd_list.user_detail_id,
        arrange_work: new_bork_hour,
      };
      this.$forceUpdate();
      console.log(this.push_list);
    },

    // 删除排班时间段
    close_pushlist(item) {
      console.log(this.push_list);
      this.$delete(this.push_list, item.jd_id);
      this.dateTime = [];
    },

    // 最后确定提交
    onSubmit() {
      console.log(this.push_list);

      var push_data = {
        project_id: this.Select_jd_list.user_detail_id,
        elite_id: this.elite_id,
        arrange: [],
      };

      console.log(this.Select_jd_list);

      // 先判断this.push_list 是否有值 如果没有 继续去判断
      if (
        this.push_list == undefined ||
        JSON.stringify(this.push_list) == "{}"
      ) {
        if (this.Select_jd_list == "" || this.Select_jd_list.length == 0) {
          this.$message.error("请选择职位");
        } else {
          if (this.dateTime.length == 0) {
            this.$message.error("请选择时间");
          } else {
            var new_bork_hour = [];
            var work_hour_data = this.Select_jd_list.work_hour.split("-");
            this.dateTime.map((item) => {
              new_bork_hour.push(
                Date.parse(item + " " + work_hour_data[0]) / 1000 +
                  "-" +
                  Date.parse(item + " " + work_hour_data[1]) / 1000
              );
            });
            push_data.arrange = [
              {
                project_id: this.Select_jd_list.user_detail_id,
                jd_id: this.Select_jd_list.jd_id,
                arrange_work: new_bork_hour.toString(),
              },
            ];
            this.push_odd_job(push_data);
          }
        }
      } else {
        Object.keys(this.push_list).map((item) => {
          push_data.arrange.push({
            project_id: this.push_list[item].project_id,
            jd_id: this.push_list[item].jd_id,
            arrange_work: this.push_list[item].arrange_work.toString(),
          });
        });
        this.push_odd_job(push_data);
      }
    },
    // 推送排班
    push_odd_job(push_data) {
      this.vvloading = true;
      push_odd_job({ push_data }).then((res) => {
        if (!res.code) {
          this.$message.success("推送排班成功");
          this.vvloading = false;
          this.innerDrawer = false;
          this.vloading = true;
          this.push_data = {};
          this.radar_detailFn();
          this.get_record();
          this.no();
        } else {
          this.$message.error(res.msg);
          this.vvloading = false;
        }
      });
    },
    // 上一个月
    preTime() {
      if (this.ismonth == 1) {
        this.isyear--;
        this.ismonth = 12;
      } else {
        this.ismonth--;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
    // 下一个月
    nextTime() {
      if (this.ismonth == 12) {
        this.isyear++;
        this.ismonth = 1;
      } else {
        this.ismonth++;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },

    // 地点
    show_client_place() {
      show_client_place().then((res) => {
        if (!res.code) {
          this.staff_form_city = res.data;
        }
      });
    },
  },

  created() {
    this.get_list();
    this.get_place();
    this.show_client_place();
    sessionStorage.setItem("nav_id", 3);
  },
};
</script>

<style lang="less" scoped>
.drawer {
  display: flex;
  flex-wrap: wrap;
  .el-form /deep/ .el-form-item__label {
    width: 85px;
  }
  > .left {
    width: 65%;
    box-sizing: border-box;
    padding: 20px;
    border-right: 1px solid #eee;
  }
  > .right {
    box-sizing: border-box;
    padding: 20px;
    width: 35%;
    .right_input {
      margin-top: 20px;
    }
    .recorList {
      margin-top: 20px;
      .el-timeline {
        padding: 0;
      }
    }
  }
  .title {
    font-size: 20px;
    // font-weight: bold;
    display: flex;
    justify-content: space-between;
    .bj {
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.el-form {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-form .el-form-item {
  width: 50%;
}
.el-form /deep/ .el-form-item__label {
  width: 100px;
}
.tags {
  margin-bottom: 30px;
}
.tags .tags_title {
  margin-bottom: 20px;
}
.tags .el-tag {
  margin-right: 15px;
  margin-bottom: 20px;
}
.el-form-item /deep/ .el-input {
  width: 70%;
}
.el-cascader {
  width: 70%;
}
.el-cascader /deep/ .el-input {
  width: 100% !important;
}
.Timeline_title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 20px;
  font-size: 20px;
  // font-weight: bold;
}
.Timeline .Job_info {
  position: absolute;
  right: 16%;
  bottom: 20%;
  width: 80px;
  height: 80px;
  div {
    width: 100%;
    height: 100%;
  }
}
.Timeline .info {
  margin: 0;
  // width: 60%;
  display: flex;
  border-bottom: 1px solid #aaa;
  line-height: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 20px 20px;
  position: relative;
  .img {
    position: absolute;
    right: -20px;
    top: -20px;
    height: 80px;
    width: 80px;
  }
  div {
    margin-right: 20px;
  }
  .time {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
.push {
  padding-left: 15px;
  display: flex;
  .calendar {
    width: 60%;
  }
  .timetop {
    width: 428px;
  }
  .week {
    width: 428px;
  }
  .onlist_ {
    width: 428px;
  }
}
.All_calendars {
  margin: 0 auto;
}
.jd_list {
  .jd_list_title {
    margin-bottom: 20px;
    font-weight: bold;
    width: 90%;
    box-sizing: border-box;
  }
  .jd_list_title_two {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
}
.jd_list .el-checkbox {
  width: 90%;
  margin: 8px 10px;
  // font-size: 30px;
  box-sizing: border-box;
}
.Push_button_wrap {
  display: flex;
  justify-content: flex-end;
  .Push_button {
    margin: 30px;
  }
}
.choice_project_list {
  width: 40%;
  margin-left: 10px;
  .choice_wrap {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .left_title {
      font-weight: bold;
    }
  }
  .el-radio-group {
    margin: 20px 0;
  }
  .el-radio {
    width: 90%;
    margin: 8px 10px 8px 0;
    box-sizing: border-box;
  }
  .el-checkbox-group {
    margin: 20px 0;
  }
  .el-checkbox {
    width: 90%;
    margin: 8px 10px 8px 0;
    box-sizing: border-box;
  }
}
.calendar {
  width: 428px;
  .timetop {
    display: flex;
    justify-content: center;
    .timetop_title {
      padding: 0 40px;
    }
  }
  .week {
    display: flex;
    flex-wrap: wrap;
    div {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 12%;
      margin: 1%;
    }
  }
  .time_list {
    border-left: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    .itemlist_div {
      box-sizing: border-box;
      text-align: center;
      width: 60px;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      position: relative;
      cursor: pointer;
      .iconfont {
        position: absolute;
        top: 3px;
        right: 3px;
      }
      &:nth-child(-n + 7) {
        border-top: 1px solid #eee;
      }
      .old_day {
        color: #999;
      }
      .old_day:hover {
        cursor: not-allowed;
        border: 3px solid #fff;
      }
      .active_day {
        border: 3px solid #eee;
        background-color: #eee;
      }
      div {
        border: 3px solid #fff;
        line-height: 45px;
      }
      div:hover {
        border: 3px solid #efefef;
      }
      .no_class {
        cursor: default;
      }
      .no_class:hover {
        border: 3px solid #fff;
      }
    }
  }
  .TimeSelect {
    border: 3px solid #ff8201 !important;
    position: relative;
    .iconfont_wrap {
      display: block !important;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #000;
    }
  }
  .iconfont_wrap {
    display: none;
  }
  .oldSelect {
    border: 3px solid #ff8201 !important;
    background-color: #ff8201;
    color: #fff;
  }

  .calendar_time_list {
    div {
      position: relative;
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
      padding: 4px 24px;
      line-height: 25px;
      margin: 10px 0;
    }
    i {
      position: absolute;
      right: 10px;
      top: 2px;
      cursor: pointer;
    }
  }
}
.add_time_wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  .el-date-editor.el-input {
    margin-right: 20px;
  }
}
.add_time_list {
  > div {
    justify-content: space-between;
    background-color: #ecf5ff;
    border-color: #faecd8;
    color: #409eff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    line-height: 30px;
    padding: 0 10px;
    display: flex;
    margin-bottom: 20px;
    .close {
      cursor: pointer;
      display: none;
    }
    &:hover .close {
      display: block;
    }
  }
}
.end_Scheduling {
  width: 95%;
  margin: 20px auto 40px;
  display: flex;
  justify-content: flex-end;
}
.on_radio_div {
  white-space: initial;
  line-height: 25px;
  margin-top: 10px;
  padding-left: 5px;
}

.ispingjia_cont_wrap {
  height: 300px;
  overflow: auto;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #bfbfbf;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(139, 136, 136, 20%);
    border-radius: 10px;
    background: #ffffff;
  }
}

.staff_form /deep/ .el-form-item {
  width: 90%;
}
.staff_form /deep/ .el-form-item__label {
  width: 100%;
  text-align: left;
}
.staff_form /deep/ .el-select {
  width: 100%;
}
.staff_form /deep/ .el-input {
  width: 100%;
}
.staff_form /deep/ .el-cascader {
  width: 100% !important;
}
.age_fanwei {
  width: 45% !important;
}
.button_footer{
  display: flex;
  justify-content: space-between;
}
</style>

<style lang="less">
.add_time_list_authentication {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #cdcdcd;
  padding: 10px 10px;
  div {
    font-size: 14px;
    line-height: 30px;
  }
  &:last-child {
    border: none;
  }
}
</style>