<template>
  <div class="list_wrap" v-loading="loading">
    <div style="display: flex; justify-content: space-between">
      <el-button type="warning" plain @click="$router.go(-1)" v-if="tr_id != 4"
        >返回</el-button
      >
      <div v-else></div>
      <div style="display: flex; align-items: center">
        <el-button
          type="info"
          plain
          @click="
            $router.push({
              path: '/company/servicecharge',
              query: { id: project_id },
            })
          "
          >项目费用明细</el-button
        >
        <div style="margin-left: 20px">
          <el-tooltip placement="bottom" effect="light">
            <div slot="content">
              <div
                v-for="(item, index) in staff_list"
                :key="index"
                class="charge"
              >
                <span>姓名：{{ item.name }}</span
                ><span>手机号：{{ item.username }}</span>
              </div>
            </div>
            <div v-if="tr_id == 4">
              <el-tag style="padding:0 20px;height:40px;line-height: 40px;cursor: pointer;" type="success">联系负责人</el-tag>
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
    <div v-if="list.length != 0">
      <div class="content">
        <div class="list" v-for="(item, index) in list" :key="index">
          <template>
            <div class="text">
              <div class="title">
                {{ item.jd_place }} | {{ item.jd_title }}
                <el-button
                  type="primary"
                  plain
                  size="mini"
                  @click.stop="details(item)"
                  v-if="tr_id != 4"
                  style="padding: 7px 10px; margin-left: 10px"
                  >查看详情</el-button
                >
              </div>
              <div class="price">
                {{ item.jd_salary_max }}&nbsp;元/&nbsp;{{ item.jd_salary_type }}
              </div>
              <div class="work_hour">
                工作时间：<span v-html="time_filter(item.work_day)"></span>
                {{ item.work_hour }}
              </div>
            </div>
            <div class="tips">
              <div>
                <div class="info">
                  招聘人数：{{ item.jd_people_max }}&nbsp;|&nbsp;年龄要求：<span
                    v-if="item.jd_age_min != item.jd_age_max"
                    >{{ item.jd_age_min }}岁 - {{ item.jd_age_max }}岁</span
                  ><span v-else>{{ item.jd_age_min }}岁</span
                  >&nbsp;|&nbsp;性别要求：<span v-if="item.jd_gender == 0"
                    >性别不限</span
                  ><span v-if="item.jd_gender == 1">男</span
                  ><span v-if="item.jd_gender == 2">女</span>
                </div>
                <div class="place"></div>
                <div class="detailed">详细地址：{{ item.jd_place_detail }}</div>
              </div>
            </div>
            <div class="right_div">
              <div class="text not_finished" @click.stop="oddjobInfo(item)">
                上工管理
              </div>
            </div>
            <div class="state">
              <img
                v-if="item.odd_status == 0"
                src="~@/assets/images/main/odd_status0.png"
                alt=""
              />
              <img
                v-if="item.odd_status == 1"
                src="~@/assets/images/main/odd_status1.png"
                alt=""
              />
            </div>
          </template>
        </div>
      </div>
      <!-- 抽屉 -->
      <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :before-close="close_drawer"
        :with-header="false"
      >
        <div v-loading="vloading" class="info_wrap" style="min-height: 100vh">
          <div class="jd_title">
            {{ info.jd_title }}
            <span class="small">招{{ info.jd_people_max }}人</span>
          </div>
          <div class="price">
            {{ info.jd_salary_max }}元/{{ info.jd_salary_type }}
          </div>
          <div class="position">
            <p v-if="info.work_hour">
              工作时段：<span v-html="time_filter(info.work_day)"></span>
              {{ info.work_hour }}
            </p>
            <p>
              年龄要求：<span v-if="info.jd_age_min != info.jd_age_max"
                >{{ info.jd_age_min }}岁 - {{ info.jd_age_max }}岁</span
              ><span v-else>{{ info.jd_age_min }}岁</span>
            </p>
            <p>
              性别要求：<span v-if="info.jd_gender == 0">性别不限</span
              ><span v-if="info.jd_gender == 1">男</span
              ><span v-if="info.jd_gender == 2">女</span>
            </p>
          </div>
          <div class="show_title_left">职位技能</div>
          <div class="job-hot">
            <div v-if="info.jd_health == 1" style="background-color: #ff2c2c">
              需要健康证
            </div>
            <div v-for="(item, index) in info.jd_skill_list" :key="index">
              {{ item }}
            </div>
          </div>
          <div class="show_title_left">职位描述</div>
          <div>
            {{ info.jd_desc }}
          </div>
          <div class="show_title_left">工作地点</div>
          <p v-if="info.jd_place_data">
            {{ info.jd_place_data.province_name }}
            {{ info.jd_place_data.city_name }}
            {{ info.jd_place_data.district_name }}
            {{ info.jd_place_detail }}
          </p>
        </div>
      </el-drawer>
    </div>
    <div v-else style="padding: 180px 0">
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
Vue.prototype.time_filter = function (value) {
  let time = value.split(",");
  let length = time.length;
  if (length > 1) {
    return (
      time[0].split("-")[0] +
      "年" +
      time[0].split("-")[1] +
      "月" +
      time[0].split("-")[2] +
      "日" +
      " - " +
      time[length - 1].split("-")[0] +
      "年" +
      time[length - 1].split("-")[1] +
      "月" +
      time[length - 1].split("-")[2] +
      "日"
    );
  } else {
    return (
      time[0].split("-")[0] +
      "年" +
      time[0].split("-")[1] +
      "月" +
      time[0].split("-")[2] +
      "日"
    );
  }
};
import { jd_list, jd_info, p_list } from "@/api/project";
export default {
  data() {
    return {
      vloading: false,
      loading: true,
      // 权限
      tr_id: "",
      // 列表
      list: [],
      // 抽屉
      drawer: false,
      // 职位详情信息
      info: {},
      // 项目id
      project_id: "",
      // 职位ID
      jd_id: "",
      // 负责人列表
      staff_list: [],

      //  //时间
      //当前年月日
      isyear: "",
      ismonth: "",
      isday: "",
      // 排班的时间
      SchedulingTime: [],
      // 旧的
      week: ["日", "一", "二", "三", "四", "五", "六"],
      time_frame: "", // 时间范围
      days: "", // 周的下标
      dateTime: [], // 日期时间
      // 新的
      Warehouse: {}, // 仓库
      this_month: {}, // 当前月份
      item_children: [],
    };
  },
  methods: {
    // 关闭抽屉前回调
    close_drawer(done) {
      done();
      this.info = {};
    },
    // 点击查看招聘进度
    progress_drawer(item) {
      this.jd_id = item.jd_id;
    },

    // 查看职位详情
    details(item) {
      this.drawer = true;
      this.vloading = true;
      // item.jd_id
      jd_info({ jd_id: item.jd_id }).then((res) => {
        if (!res.code) {
          this.info = res.data;
          this.vloading = false;
          console.log(this.info);
        }
      });
    },
    // 跳转到信息页面
    oddjobInfo(item) {
      console.log(item.jd_id);
      // var jd_id = item.jd_id
      this.$router.push({
        path: "/company/oddjobInfo",
        query: { id: item.jd_id },
      });
      console.log(item);
    },

    // 我的项目列表
    p_list() {
      p_list().then((res) => {
        if (!res.code) {
          this.project_id = res.data.project_list[0].user_detail_id;
          this.jd_list(res.data.project_list[0].user_detail_id);
        }
      });
    },
    // 我的项目 职位
    jd_list(project_id) {
      jd_list({ project_id: project_id }).then((res) => {
        if (!res.code) {
          this.staff_list = res.data.staff_list;
          this.list = res.data.jd_list;
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.tr_id = sessionStorage.getItem("tr_id");
    if (this.tr_id == 4) {
      this.p_list();
    } else {
      this.project_id = this.$route.query.id;
      this.jd_list(this.project_id);
    }
  },
};
</script>

<style lang="less" scoped>
.wrap {
  > div {
    width: 100%;
  }
  .list {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #aaa;
    &:last-child {
      border: none;
    }

    .title {
      font-size: 18px;
      color: #333;
      font-weight: bold;
    }
    .price {
      color: #ff552e;
      font-size: 16px;
      font-weight: bold;
      margin: 7px 0;
    }
    .right_div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .text {
        padding: 5px 20px;
      }
      .not_finished {
        width: auto;
        margin: 0 140px 0 60px;
        background-color: #fef0f0;
        border: 1px solid #fde2e2;
        color: #f56c6c;
        cursor: pointer;
      }
      .finished {
        background-color: #f0f9eb;
        border: 1px solid #e1f3d8;
        color: #67c23a;
      }
    }
    .tips {
      display: flex;
      align-items: center;
      text-align: center;
      div {
        width: 100%;
      }
    }

    .text,
    .tips,
    .right_div {
      width: 33%;
    }
    .info {
      font-size: 14px;
      color: #aaa;
    }
    .place {
      margin: 10px 0;
      font-size: 14px;
      color: #aaa;
    }
    .detailed {
      font-size: 12px;
      color: #aaa;
    }
    .state {
      position: absolute;
      right: 0;
      top: 30px;
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .info_wrap {
    padding: 10px 30px;
    .jd_title {
      font-size: 30px;
      margin: 30px 0;
      .small {
        font-size: 12px;
        border: 1px solid #f90;
      }
    }
    .price {
      color: red;
      font-size: 22px;
    }
    .position {
      margin-right: 3px;
      border-bottom: 1px solid #f4f4f5;
      p {
        font-size: 16px;
        margin: 16px 0;
      }
    }
    .show_title_left {
      padding-left: 7px;
      margin-top: 60px;
      margin-bottom: 20px;

      color: #333;
      line-height: 16px;
      font-size: 16px;
      border-left: 3px solid #ff9900;
    }
    .job-hot {
      display: flex;
      flex-wrap: wrap;
      > div {
        margin-bottom: 10px;
        line-height: 30px;
        color: #fff;
        height: 30px;
        padding: 0 15px;
        margin-right: 10px;
        border-radius: 5px;
        background-color: #ff9900;
      }
    }
  }
}
.charge {
  line-height: 30px;
  display: flex;
  span {
    &:first-child {
      width: 150px;
    }
  }
}
</style>