<template>
  <div class="day_resume_count" v-loading="loading">
    <div class="overall">
      <div class="all_card_title">
        <div class="vertical"></div>
        整体数据看板
      </div>

      <div class="all_data">
        <div class="num_all contract-color">
          <div class="contract_num_all_title">
            <div>
              <img
                class="title_icon"
                src="~@/assets/images/main/home_xiangmu.png"
                alt
              />
            </div>
            <div class="title_txt">订单数据</div>
          </div>
          <div class="num_all_body" @click="jump('pay_charge')">
            <div class="num_title">总金额(元)</div>
            <div class="number">{{ isdata.pay_charge }}</div>
          </div>
          <div class="num_all_body" @click="jump('all_jd')">
            <div class="num_title">订单总数</div>
            <div class="number">{{ isdata.all_jd }}</div>
          </div>
          <div class="num_all_body" @click="jump('underway_jd')">
            <div class="num_title">进行中订单</div>
            <div class="number">{{ isdata.underway_jd }}</div>
          </div>
          <div class="num_all_body" @click="jump('expire_odd_jd')">
            <div class="num_title">已结束订单</div>
            <div class="number">{{ isdata.expire_odd_jd }}</div>
          </div>
          <div class="num_all_body" @click="jump('service_charge')">
            <div class="num_title">总服务费(元)</div>
            <div class="number">{{ isdata.service_charge }}</div>
          </div>
        </div>

        <div class="num_all resume-color" v-if="tr_id != 4">
          <div class="resume_num_all_title">
            <div>
              <img
                class="title_icon"
                src="@/assets/images/main/home_leida.png"
                alt
              />
            </div>
            <div class="title_txt">身份证认证数据</div>
          </div>
          <div class="num_all_body" @click="jump('auth_count')">
            <div class="num_title">认证总数</div>
            <div class="number">{{ isdata.auth_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('month_auth_count')">
            <div class="num_title">本月认证数据</div>
            <div class="number">{{ isdata.month_auth_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('week_auth_count')">
            <div class="num_title">本周认证数据</div>
            <div class="number">{{ isdata.week_auth_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('today_auth_count')">
            <div class="num_title">今日认证数据</div>
            <div class="number">{{ isdata.today_auth_count }}</div>
          </div>
        </div>

        <div class="num_all id-color" v-if="tr_id != 4">
          <div class="id_num_all_title">
            <div>
              <img
                class="title_icon"
                src="@/assets/images/main/home_renzheng.png"
                alt
              />
            </div>
            <div class="title_txt">健康证认证数据</div>
          </div>
          <div class="num_all_body" @click="jump('h_auth_count')">
            <div class="num_title">认证总数</div>
            <div class="number">{{ isdata.h_auth_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('month_h_auth_count')">
            <div class="num_title">本月认证数据</div>
            <div class="number">{{ isdata.month_h_auth_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('week_h_auth_count')">
            <div class="num_title">本周认证数据</div>
            <div class="number">{{ isdata.week_h_auth_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('today_h_auth_count')">
            <div class="num_title">今日认证数据</div>
            <div class="number">{{ isdata.today_h_auth_count }}</div>
          </div>
        </div>
        <div class="num_all account-color">
          <div class="account_num_all_title">
            <div>
              <img
                class="title_icon"
                src="@/assets/images/main/home_shanggong.png"
                alt
              />
            </div>
            <div class="title_txt">报名数据</div>
          </div>
          <div class="num_all_body" @click="jump('all_apply_count')">
            <div class="num_title">累计报名数据</div>
            <div class="number">{{ isdata.all_apply_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('today_apply_count')">
            <div class="num_title">今日报名数据</div>
            <div class="number">{{ isdata.today_apply_count }}</div>
          </div>
        </div>
        <div class="num_all social-color">
          <div class="social_num_all_title">
            <div>
              <img
                class="title_icon"
                src="@/assets/images/main/home_jiesuan.png"
                alt
              />
            </div>
            <div class="title_txt">上工数据</div>
          </div>
          <div class="num_all_body" @click="jump('all_work_count')">
            <div class="num_title">累计上工数据</div>
            <div class="number">{{ isdata.all_work_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('today_work_count')">
            <div class="num_title">今日上工数据</div>
            <div class="number">{{ isdata.today_work_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('all_work_hour')">
            <div class="num_title">总累计工时数据(小时)</div>
            <div class="number">{{ isdata.all_work_hour }}</div>
          </div>
          <div class="num_all_body" @click="jump('today_work_hour')">
            <div class="num_title">今日累计工时数据(小时)</div>
            <div class="number">{{ isdata.today_work_hour }}</div>
          </div>
        </div>

        <!-- <div class="num_all social-color">
          <div class="social_num_all_title">
            <div>
              <img
                class="title_icon"
                src="@/assets/images/main/home_jiesuan.png"
                alt
              />
            </div>
            <div class="title_txt">结算数据</div>
          </div>
          <div class="num_all_body" @click="jump('all_clear_count')">
            <div class="num_title">结算数据</div>
            <div class="number">{{ isdata.all_clear_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('already_clear_count')">
            <div class="num_title">已结算</div>
            <div class="number">{{ isdata.already_clear_count }}</div>
          </div>
          <div class="num_all_body" @click="jump('not_clear_count')">
            <div class="num_title">未结算</div>
            <div class="number">{{ isdata.not_clear_count }}</div>
          </div>
        </div> -->
      </div>
    </div>
    <!-- <div class="staff" v-if="tr_id != 4">
      <div class="all_card_title">
        <div class="vertical"></div>
        员工数据看板
      </div>
      <el-table
        :data="staffData"
        style="width: 100%"
        stripe
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="name" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="all_radar_count" label="雷达总数">
        </el-table-column>
        <el-table-column prop="all_odd_count" label="累计上工人数">
        </el-table-column>
        <el-table-column prop="all_clear_count" label="累计结算人数">
        </el-table-column>
        <el-table-column prop="today_radar_count" label="今日新增雷达数">
        </el-table-column>
        <el-table-column prop="today_odd_count" label="今日上工人数">
        </el-table-column>
        <el-table-column prop="today_clear_count" label="今日结算人数">
        </el-table-column>
      </el-table>
    </div> -->
  </div>
</template>
<script>
import { home, staff_data } from "@/api/data_board";
export default {
  data() {
    return {
      loading: false,
      // 权限ID
      tr_id: "",
      // 整体数据看板数据
      isdata: {},
      // 员工数据看板
      staffData: [],
      // 选择日期筛选
      data_picker: "",
    };
  },
  methods: {
    // 跳转详情页
    jump(val) {
      this.$router.push({
        path: "/company/data_details",
        query: { select_data: val },
      });
    },
    //员工数据看板table样式
    tableRowClassName({ row, rowIndex }) {
      console.log(rowIndex);
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else {
        return "success-row";
      }
      return "";
    },
    // 选择日期筛选
    date_change() {
      console.log(this.data_picker);
    },
    // 整体数据看板
    home() {
      home().then((res) => {
        if (!res.code) {
          console.log(res.data);
          this.isdata = res.data;
        } else {
          this.$massage.error(res.msg);
          this.loading = false;
        }
      });
    },
    // 员工数据看板
    staff_data() {
      staff_data().then((res) => {
        if (!res.code) {
          this.staffData = res.data.staff_data_list;
          console.log(this.staffData);
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.tr_id = sessionStorage.getItem("tr_id");
    this.loading = true;
    sessionStorage.setItem("nav_id", 1);
    this.home();
    this.staff_data();
  },
};
</script>


<style scoped>
.num_title {
  font-size: 14px;
}

.number {
  font-size: 18px;
}
.title_txt {
  color: #fff;
  font-size: 16px;
  margin-left: 10px;
  font-weight: 400;
}

.title_icon {
  width: 40px;
  height: 40px;
}

.resume_num_all_title {
  width: 200px;
  display: flex;

  justify-content: center;
  align-items: center;
  background: rgb(88, 202, 121);
}
.id_num_all_title {
  width: 200px;
  display: flex;

  justify-content: center;
  align-items: center;
  background: rgb(19 91 255);
}

.id-color {
  background: rgb(179 217 240);
}
.resume-color {
  background: rgb(179, 240, 196);
}

.contract_num_all_title {
  width: 200px;
  background-color: rgb(92, 137, 253);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contract-color {
  background-color: rgb(202, 214, 247);
}

.num_all_body div {
  width: 200px;
}

.num_all_body {
  cursor: pointer;
  color: #333;
  font-weight: 600;
  width: 200px;
  padding-top: 10px;
  text-align: center;
  line-height: 40px;
  /* padding-left: 100px; */
}

.social_num_all_title {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(233, 75, 63);
}
.social-color {
  background: rgb(252, 213, 213);
}

.account_num_all_title {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(240, 168, 102);
}
.account-color {
  background: rgb(248, 219, 193);
}

.num_all {
  margin-bottom: 6px;
  height: 100px;
  width: 97%;
  display: flex;
  /* background:rgb(235, 235, 235) */
}

.all_data {
  /* background: #ccc; */
  width: 100%;
  padding: 20px;
}

.vertical {
  height: 16px;
  width: 4px;
  background: #ff8201;
  margin-right: 10px;
}

.all_card_title {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 30px;
  font-weight: 600;
  width: 100%;
  /* background: red; */
}

#account {
  float: left;
  height: 340px;
  width: 100%;
}

.overall {
  padding: 20px;
  background: #ffff;
  border-radius: 8px;
  -moz-box-shadow: 2px 2px 5px #bdbdbd;
  -webkit-box-shadow: 2px 2px 5px #bdbdbd;
  box-shadow: 2px 2px 5px #bdbdbd;
  margin-bottom: 20px;
}

.day_resume_count {
  /* min-height: 80vh; */
  /* background: #fff; */
  /* padding: 10px 20px; */
  margin-bottom: 50px;
}
.day_resume_count > p {
  padding: 20px 0;
  color: #41b883;
}

.day_resume_count > div:first-child > p > span {
  color: #41b883;
}
.staff {
  width: calc(100% - 24px);
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 2px 2px 5px #bdbdbd;
}
/* 员工数据看板筛选日期 */
.date_picker {
  margin: 12px;
}
</style>
