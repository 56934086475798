<template>
  <div class="list_wrap" v-loading="loading">
    <div v-if="data_list.length != 0">
      <div
        class="data_list"
        v-for="(item, index) in data_list"
        @click="goPosition(item)"
        :key="index"
      >
        <div class="img">
          <img :src="item.company_logo_url" alt="" />
        </div>
        <div class="tips">
          <div>
            <div class="title">{{ item.company_name }}</div>
            <div class="short_name">品牌名：{{ item.company_short_name }}</div>
            <div class="region">{{ item.company_place }}</div>
          </div>

          <div class="distribution" @click.stop v-if="tr_id == 2">
            <el-tooltip v-if="!item.primaryShow" placement="bottom">
              <div slot="content" class="distribution_more">
                <template>
                  <div v-for="(list, index) in item.user_list" :key="index">
                    <span>{{ list.name }}</span>
                  </div>
                </template>
              </div>
              <el-button :disabled="item.user_list.length <= 3 ? true : false"
                ><span
                  >分配人员：<span v-if="item.user_list.length == 0"
                    >无</span
                  ></span
                ><template v-for="list in item.user_list.slice(0, 3)">
                  {{ list.name }}&nbsp;&nbsp; </template
                ><template v-if="item.user_list.length > 3"
                  >...</template
                ></el-button
              >
            </el-tooltip>
            <el-button
              type="primary"
              @click.stop="primary(item)"
              v-if="!item.primaryShow"
              >分配</el-button
            >

            <template v-if="item.primaryShow">
              <el-select
                collapse-tags
                v-model="staff"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="item in staff_list"
                  :key="item.user_id"
                  :label="item.name"
                  :value="item.user_id"
                >
                </el-option>
              </el-select>
              <el-button
                class="determine"
                type="primary"
                @click="determine(item)"
                >确认分配</el-button
              >
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-else style="padding: 180px 0">
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { p_list, get_staff, allot_project } from "@/api/project";
export default {
  data() {
    return {
      loading: true,
      // 权限ID
      tr_id: 0,
      // 列表
      data_list: [],
      // 客服列表
      staff_list: [],
      staff: "",
    };
  },
  methods: {
    //获取列表
    getlist() {
      p_list().then((res) => {
        if (!res.code) {
          var list = res.data.project_list;
          list.forEach((item) => {
            this.$set(item, "primaryShow", false);
          });
          this.data_list = list;
          this.loading = false;
          // console.log(res.data.project_list);
        }
      });
    },
    // 获取公司客服
    getStaff() {
      get_staff().then((res) => {
        if (!res.code) {
          this.staff_list = res.data.staff_list;
        }
        // console.log(this.staff_list);
      });
    },
    // 前往项目职位
    goPosition(item) {
      this.$router.push({
        path: "/company/position",
        query: { id: item.user_detail_id },
      });
    },
    // 点击分配
    primary(item) {
      console.log(item);
      // 取消所有的显示
      this.data_list.forEach((item) => {
        item.primaryShow = false;
      });
      // 点击的显示
      item.primaryShow = true;
      // 循环出点击下项目的客服
      var list = item.user_list.map((item) => {
        return item.user_id;
      });
      // 回显
      this.staff = list;
    },
    // 确认分配
    determine(item) {
      // 取消显示
      item.primaryShow = false;
      this.loading = true;
      // 调用接口
      allot_project({
        user_list: this.staff,
        project_id: item.user_detail_id,
      }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
          this.getlist();
        } else {
          this.$message.error(res.msg);
          this.loading = false;
        }
      });
      // 客服列表为空
      this.staff = "";
    },
  },

  created() {
    this.getlist();
    this.getStaff();
    // 获取权限
    this.tr_id = sessionStorage.getItem("tr_id");
    sessionStorage.setItem("nav_id", 2);
  },
};
</script>

<style lang="less" scoped>
.data_list {
  cursor: pointer;
  display: flex;
  margin: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  &:last-child {
    border-bottom: none;
  }
  .img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
    }
  }
  .tips {
    width: calc(100% - 100px);
    margin-left: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 20px;
      color: #000;
      margin-bottom: 6px;
    }
    .short_name{
      font-size: 16px;
      margin-bottom: 6px;
    }
    .region {
      color: #aaa;
      font-size: 14px;
    }
  }
  .determine {
    margin-left: 20px;
  }
  .distribution /deep/ .el-button {
    border: none;
  }
  .distribution /deep/ .el-tooltip:hover {
    color: initial !important;
    background-color: initial;
  }
  .distribution /deep/ .el-button.is-disabled {
    cursor: inherit;
    color: #606266;
  }
  .distribution /deep/ .el-button.is-disabled:hover {
    // color: #c0c4cc !important;
  }
  .distribution {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="less">
.distribution_more {
  display: flex;
  max-width: 300px;
  flex-wrap: wrap;
}
.distribution_more div {
  margin: 5px 10px;
}
</style>
