<template>
  <div class="list_wrap" v-loading="loading">
    <el-button type="warning" plain @click="$router.go(-1)">返回</el-button>
    <div>
      <div class="calendar" v-if="JSON.stringify(this_month) != '{}'">
        <div class="timetop">
          <div @click="preTime" style="cursor: pointer">
            <i class="iconfont icon-shangyiye"></i>
          </div>
          <div class="timetop_title">{{ this_month[0].time }}</div>
          <div @click="nextTime" style="cursor: pointer">
            <i class="iconfont icon-xiayiye"></i>
          </div>
        </div>
        <div class="week">
          <div v-for="(item, index) in week" :key="index">{{ item }}</div>
        </div>
        <div>
          <div class="time_list">
            <div
              class="itemlist_div"
              v-for="(item, index) in this_month[0].item_children"
              :key="index"
            >
              <div
                :class="{
                  TimeSelect:
                    dateTime == item.item_title + '-' + item.item_text,
                }"
                @click="new_catdate(item)"
                v-if="
                  item.item_text != undefined &&
                  Object.keys(all_process).indexOf(
                    item.item_title +
                      '-' +
                      item.item_text.toString().padStart(2, '0')
                  ) != -1
                "
              >
                {{ item.item_text }}
              </div>
              <div v-else style="color: rgb(197 197 197)">
                {{ item.item_text }}
              </div>
              <p v-if="item.item_title" class="itemlist_div_p">
                <span
                  v-if="
                    all_process[
                      item.item_title +
                        '-' +
                        item.item_text.toString().padStart(2, '0')
                    ]
                  "
                  >{{
                    all_process[
                      item.item_title +
                        "-" +
                        item.item_text.toString().padStart(2, "0")
                    ].odd_people +
                    " / " +
                    all_process[
                      item.item_title +
                        "-" +
                        item.item_text.toString().padStart(2, "0")
                    ].jd_people
                  }}</span
                >
              </p>
              <!-- 已选中时间 和  SchedulingTime里面的键值有的日期变色-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <el-table :data="data_list" stripe style="width: 100%">
        <el-table-column prop="name" label="姓名" width="90"> </el-table-column>
        <el-table-column prop="gender" label="性别" width="60">
        </el-table-column>
        <el-table-column
          v-if="tr_id != 4"
          prop="phone"
          label="联系方式"
          width="140"
        >
        </el-table-column>

        <el-table-column prop="id_card_number" label="身份证号" width="200">
          <template slot-scope="scope">
            {{ scope.row.id_card_number ? scope.row.id_card_number : "暂无" }}
          </template>
        </el-table-column>

        <el-table-column label="岗位薪资(元/小时)" width="140">
          <template slot-scope="scope">
            {{ scope.row.jd_salary != "" ? scope.row.jd_salary : "暂无" }}
          </template>
        </el-table-column>
        <el-table-column label="上班打卡时间" width="170">
          <template slot-scope="scope">
            {{
              scope.row.punch_start_time != ""
                ? scope.row.punch_start_time
                : "暂无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="下班打卡时间" width="170">
          <template slot-scope="scope">
            {{
              scope.row.punch_end_time != "" ? scope.row.punch_end_time : "暂无"
            }}
          </template>
        </el-table-column>
        <el-table-column label="上工时长(小时)" width="130">
          <template slot-scope="scope">
            {{ scope.row.clearing_time }}
          </template>
        </el-table-column>

        <el-table-column prop="punch_money" label="工资(元)" width="100">
          <template slot-scope="scope">
            {{ scope.row.punch_money }}
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="320">
          <template slot-scope="scope">
            <!-- <el-button @click="overtime(scope.row)" size="small"
              >加班</el-button
            > -->
            <el-button @click="cancel(scope.row)" size="small" v-if="tr_id == 3"
              >取消排班</el-button
            >
            <el-button
              @click="evaluate(scope.row)"
              size="small"
              v-if="tr_id == 4"
              >评价</el-button
            >
            <el-button @click="Viewevaluate(scope.row)" size="small"
              >查看零工评价</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 排班时间 -->
    <el-dialog
      title="排班修改"
      class="modify"
      :visible.sync="dialogFormVisible"
    >
      <div>
        <el-time-select
          placeholder="起始时间"
          v-model="overtime_overtime.work_up"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '24:00',
          }"
        >
        </el-time-select>
        <el-time-select
          placeholder="结束时间"
          v-model="overtime_overtime.work_down"
          :picker-options="{
            start: '00:00',
            step: '00:30',
            end: '24:00',
            minTime: overtime_overtime.work_up,
          }"
        >
        </el-time-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="ismodify">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 评价 -->
    <el-dialog
      title="评价"
      :visible.sync="isevaluate"
      width="30%"
      @close="evaluateclose"
    >
      <el-input
        type="textarea"
        :rows="6"
        placeholder="请输入内容"
        v-model="evaluateValue"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="okevaluate">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看评价列表 -->
    <el-dialog
      class="evaluate_list"
      title="评价列表"
      :visible.sync="isViewevaluate"
      width="30%"
      @close="Viewevaluateclose"
    >
      <div class="tags ispingjia">
        <div class="ispingjia_cont_wrap">
          <el-tag
            type="success"
            v-for="(item, index) in evaluate_list"
            :key="index"
            style="display: block; height: auto; line-height: 24px"
            ><div>内容：{{ item.eval }}</div>
            <div style="display: flex; justify-content: flex-end">
              时间：{{ item.create_time }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              评价人：{{ item.name }}
            </div></el-tag
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { get_odd_all_wt, getset_eval } from "@/api/radar_cue";
import {
  jd_odd_job,
  get_odd_arrange,
  update_arrange,
  set_eval,
  jd_schedule,
} from "@/api/project";
export default {
  data() {
    return {
      loading: true,
      vloading: false,
      // 抽屉开关
      isScheduling: false,
      // 权限ID
      tr_id: "",
      // 职位ID
      jd_id: "",
      // 零工ID
      elite_id: "",
      // 页码
      page: 0,
      // 列表
      data_list: [],
      //当前年月日
      isyear: "",
      ismonth: "",
      isday: "",
      // 开始结束时间
      startTime: "",
      endTime: "",
      // 排班的时间
      SchedulingTime: {},
      // 当前职位的排班时间
      currentTime: {},
      // 旧的
      week: ["日", "一", "二", "三", "四", "五", "六"],
      time_frame: "", // 时间范围
      days: "", // 周的下标
      dateTime: "", // 日期时间
      // 新的
      Warehouse: {}, // 仓库
      this_month: {}, // 当前月份
      item_children: [],

      // 加班选中的日期
      overtime_overtime: {},
      // 加班时间段修改
      dialogFormVisible: false,
      // 评价显示
      isevaluate: false,
      // 评价内容
      evaluateValue: "",
      // 查看评价列表显示
      isViewevaluate: false,
      // 评价列表
      evaluate_list: [],

      // 所有招聘进度
      all_process: {},
    };
  },
  filters: {
    // 标签多余4个字的过滤
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 4) {
        return value.slice(0, 4) + "...";
      }
      return value;
    },
    // 零工排班日历列表内容过滤
    OddSchedulingFilter(value) {
      return `${value.company}\xa0\xa0\xa0|\xa0\xa0\xa0${value.jd_name}\xa0\xa0\xa0\xa0\xa0排班时间：${value.work_up}-${value.work_down}`;
    },
  },
  methods: {
    // 排班时间查看
    onSchedulingTime(row) {
      console.log(row);
      this.elite_id = row.elite_id;
      this.isScheduling = true;
      this.formatTime();
      this.current_time_();
      // 查看零工 工作排班时间
      this.get_odd_all_wt();
    },

    // 评价
    evaluate(row) {
      this.elite_id = row.elite_id;
      this.isevaluate = true;
    },
    // 查看评价列表
    Viewevaluate(row) {
      this.isViewevaluate = true;
      this.elite_id = row.elite_id;
      getset_eval({ elite_id: this.elite_id }).then((res) => {
        if (!res.code) {
          this.evaluate_list = res.data.eval_data;
        }
      });
    },
    // 确认评价
    okevaluate() {
      var new_change = this.evaluateValue.replace(/\s*/g, "");
      if (new_change == "") {
        this.$message.error("请输入评价");
        return;
      }
      set_eval({ elite_id: this.elite_id, eval: this.evaluateValue }).then(
        (res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.evaluateValue = "";
            this.isevaluate = false;
          }
        }
      );
    },
    // 评价关闭时执行
    evaluateclose() {
      this.evaluateValue = "";
    },
    // 评价列表关闭时执行
    Viewevaluateclose() {
      this.evaluate_list = [];
    },

    // 查看零工 工作排班时间
    // get_odd_arrange() {
    //   var obj = {
    //     elite_id: this.elite_id,
    //     year: this.isyear,
    //     month: this.ismonth,
    //   };
    //   get_odd_arrange(obj).then((res) => {
    //     if (!res.code) {
    //       this.SchedulingTime = res.data.arrange_work_list;
    //       this.vloading = false;
    //       console.log(this.SchedulingTime);
    //     }
    //   });
    // },

    // 排班时间关闭前回调
    closeScheduling(done) {
      done();
    },
    // 表单点击查看
    handleClick(row) {
      console.log(row);
    },

    // 查看全部的排班考勤
    get_odd_all_wt() {
      this.vloading = true;
      get_odd_all_wt({
        elite_id: this.elite_id,
        year: this.isyear,
        month: this.ismonth,
      }).then((res) => {
        if (!res.code) {
          this.SchedulingTime = res.data.arrange_work;
          this.Current_position();
          this.vloading = false;
        }
      });
    },

    // 处理出当前职位的排班时间
    Current_position() {
      // 先把this.SchedulingTime的value值拿出来
      var list = Object.values(this.SchedulingTime);
      // 创建一个新的数组把二维变成一维
      var new_list = [];
      list.map((item) => {
        item.map((val) => {
          new_list.push(val);
        });
      });
      // 创建一个新的数组来接收过滤出来的对象
      var data_list = new_list.filter((item) => {
        return item.jd_id == this.jd_id;
      });
      // 用日期作为键值创建新的对象
      data_list.map((item) => {
        this.currentTime[item.day] = item;
      });
    },

    // 获取已排班上岗零工列表
    get_odd_job() {
      this.loading = true;
      jd_odd_job({ jd_id: this.jd_id, day: this.dateTime }).then((res) => {
        if (!res.code) {
          this.data_list = res.data.jd_odd_job;
          this.loading = false;
        }
      });
    },

    //取消排班
    cancel(list) {
      this.$confirm("是否取消当前排班", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.vloading = true;
          // 删除掉所点击键值的对象
          this.$delete(this.currentTime, list.day);
          this.update_arrange();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    // 修改排班接口
    update_arrange() {
      // 处理时间
      var time_ = [];
      time_.push(
        Date.parse(this.dateTime + " " + this.overtime_overtime.work_up) /
          1000 +
          "-" +
          Date.parse(this.dateTime + " " + this.overtime_overtime.work_down) /
            1000
      );
      console.log(time_);
      // 走接口
      // update_arrange({
      //   jd_id: this.jd_id,
      //   elite_id: this.elite_id,
      //   arrange: time_.toString(),
      // }).then((res) => {
      //   if (!res.code) {
      //     this.$message.success(res.msg);
      //     this.formatTime();
      //     this.dialogFormVisible = false;
      //     // 查看零工 工作排班时间
      //     this.get_odd_all_wt();
      //   } else {
      //     this.$message.error(res.msg);
      //     this.vloading = false;
      //     this.dialogFormVisible = false;
      //   }
      // });
    },
    // 加班
    overtime() {
      this.dialogFormVisible = true;
    },
    // 确定加班
    ismodify() {
      this.vloading = true;
      console.log(this.overtime_overtime);
      this.update_arrange();
    },
    // // // 时间
    // 上一个月
    preTime() {
      if (this.ismonth == 1) {
        this.isyear--;
        this.ismonth = 12;
      } else {
        this.ismonth--;
      }
      this.isday = 1;
      this.current_time_();
      this.get_odd_all_wt();
      // console.log(this.isyear, this.ismonth);
    },
    // 下一个月
    nextTime() {
      if (this.ismonth == 12) {
        this.isyear++;
        this.ismonth = 1;
      } else {
        this.ismonth++;
      }
      this.isday = 1;
      this.current_time_();
      this.get_odd_all_wt();
      // console.log(this.isyear, this.ismonth);
    },
    // 格式化时间
    formatTime() {
      let date = new Date();
      this.isyear = date.getFullYear();
      this.ismonth = date.getMonth() + 1;
      this.isday = date.getDate();
    },
    // 日历表
    current_time_() {
      let dateTime, this_month, Last_month, next_month;
      let yy = this.isyear;
      let mm = this.ismonth;
      let dd = this.isday;
      dateTime = `${yy}-${mm < 10 ? "0" + mm : mm}-${dd}`;
      this_month = this.obtain_time_(yy, mm);
      Last_month = this.obtain_time_(
        mm != 1 ? yy : yy - 1,
        mm != 1 ? mm - 1 : 12
      );
      next_month = this.obtain_time_(
        mm != 12 ? yy : yy + 1,
        mm != 12 ? mm + 1 : 1
      );
      console.log(Last_month);
      this.this_month = this_month;
      // console.log(Last_month, this_month, next_month);
      console.log(this_month);
      if (!this.dateTime) {
        this.dateTime = dateTime;
      }
    },
    // 获取 七天数据
    obtain_time_(yy, mm) {
      yy = Number(yy);
      mm = Number(mm);
      let item_list = [
        {
          time: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_children: [],
        },
      ]; // 返回数据
      if (this.Warehouse[`${yy}-${mm}`]) {
        item_list = this.Warehouse[`${yy}-${mm}`];
      } else {
        let dd = this.mGetDate_(yy, mm), // 获取当前月多少天
          last_days = this.days_fun(`${yy}-${mm}-1`), // 本月的第一天是周几
          next_days = this.days_fun(`${yy}-${mm}-${dd}`), // 本月的最后一天是周几
          last_list = [], // 返回数据
          next_list = []; // 返回数据
        item_list[0].item_children = this.item_list_fun(yy, mm, dd); // 获取当前所有时间
        if (last_days != 0) {
          // 第一天不是周日
          last_list = this.last_days_fun(
            mm != 1 ? yy : yy - 1,
            mm != 1 ? mm - 1 : 12,
            last_days
          );
        }
        if (next_days != 6) {
          // 最后一天不是周六
          next_list = this.next_days_fun(
            mm != 12 ? yy : yy + 1,
            mm != 12 ? mm + 1 : 1,
            next_days
          );
        }
        item_list[0].item_children = [
          ...last_list,
          ...item_list[0].item_children,
          ...next_list,
        ];

        this.Warehouse[`${yy}-${mm}`] = item_list;
      }
      return item_list;
    },
    // 当前日历本月数据
    item_list_fun(yy, mm, dd) {
      let item_children = [];
      for (let index = 1; index <= dd; index++) {
        item_children.push({
          item_title: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_text: index,
        });
      }
      return item_children;
    },
    // 当前日历展示第一行数据
    last_days_fun(yy, mm, days) {
      let item_children = [],
        Last_month = this.mGetDate_(yy, mm); // 上一月多少天
      for (let index = 0; index < days; index++) {
        item_children.unshift("");
      }
      // console.log(item_children);
      return item_children;
    },
    // 当前日历展示最后一行数据
    next_days_fun(yy, mm, days) {
      let item_children = [];
      for (let index = 1; index < 7 - days; index++) {
        item_children.push("");
      }
      return item_children;
    },
    // 计算当前日期
    Calculation_date_(yy, mm, dd, num) {
      let mGetDate = this.mGetDate_(yy, mm), // 当前月多少天
        Last_month = this.mGetDate_(
          mm != 1 ? yy : yy - 1,
          mm != 1 ? mm - 1 : 12
        ), // 上一月多少天
        new_mm = false,
        new_dd = false;
      if (num) {
        new_dd = dd + 7 > mGetDate ? true : false;
        dd = new_dd ? dd + 7 - mGetDate : dd + 7;
        if (new_dd) {
          new_mm = mm + 1 > 12 ? true : false;
          mm = new_mm ? 1 : mm + 1;
        }
        yy = new_mm ? yy + 1 : yy;
        // console.log(yy, mm, dd, num)
      } else {
        new_dd = dd - 7 < 1 ? true : false;
        dd = new_dd ? Last_month + (dd - 7) : dd - 7;
        if (new_dd) {
          new_mm = mm - 1 < 1 ? true : false;
          mm = new_mm ? 12 : mm - 1;
        }
        yy = new_mm ? yy - 1 : yy;
      }
      return `${yy}-${mm}-${dd}`;
    },
    // 本月多少天
    mGetDate_(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    // 周几
    days_fun(e) {
      return new Date(e).getDay();
    },
    // 点击日期
    new_catdate(e) {
      // console.log(Date.parse(e.item_title + "-" + e.item_text));
      this.dateTime = e.item_title + "-" + e.item_text;
      console.log(this.dateTime);
      this.get_odd_job();
    },
    // 招聘进度
    jd_schedule() {
      jd_schedule({
        jd_id: this.jd_id,
        year: this.isyear,
        month: this.ismonth,
      }).then((res) => {
        if (!res.code) {
          console.log(res.data);
          this.all_process = res.data.schedule;
          console.log(this.all_process);
        }
      });
    },
  },
  created() {
    this.jd_id = this.$route.query.id;
    this.tr_id = sessionStorage.getItem("tr_id");
    this.formatTime();
    this.current_time_();
    this.jd_schedule();
    this.get_odd_job();
    console.log(this.dateTime);
  },
};
</script>

<style lang="less" scoped>
.form {
  margin-top: 30px;
}
.calendar {
  width: 428px;
  margin: 0 auto;
  .timetop {
    display: flex;
    justify-content: center;
    .timetop_title {
      padding: 0 40px;
    }
  }
  .week {
    display: flex;
    flex-wrap: wrap;
    div {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 12%;
      margin: 1%;
    }
  }
  .time_list {
    border-left: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    .itemlist_div {
      box-sizing: border-box;
      text-align: center;
      width: 60px;
      position: relative;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      cursor: pointer;
      .iconfont {
        position: absolute;
        top: 3px;
        right: 3px;
      }
      &:nth-child(-n + 7) {
        border-top: 1px solid #eee;
      }
      .itemlist_div_p {
        color: #f90;
        font-size: 12px;
        position: absolute;
        bottom: 2px;
        display: flex;
        justify-content: center;
        width: 100%;
      }
      .old_day {
        color: #999;
      }
      .old_day:hover {
        cursor: not-allowed;
        border: 3px solid #fff;
      }
      .active_day {
        border: 3px solid #eee;
        background-color: #eee;
      }
      div {
        border: 3px solid #fff;
        line-height: 45px;
      }
      div:hover {
        border: 3px solid #efefef;
      }
      .no_class {
        cursor: default;
      }
      .no_class:hover {
        border: 3px solid #fff;
      }
    }
  }
  .TimeSelect {
    border: 3px solid #ff8201 !important;
    position: relative;
    .iconfont_wrap {
      display: block !important;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #000;
    }
  }
  .iconfont_wrap {
    display: none;
  }
  .oldSelect {
    border: 3px solid #ff8201 !important;
    background-color: #ff8201;
    color: #fff;
  }
}
.modify /deep/ .el-dialog {
  width: 35% !important;
}
.modify /deep/ .el-dialog__body {
  display: flex;
  justify-content: center;
}
.modify /deep/ .el-date-editor.el-input:first-child {
  margin-right: 20px;
}

.ispingjia_cont_wrap {
  height: 500px;
  overflow: auto;
  padding: 0 20px;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background: #bfbfbf;
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(139, 136, 136, 20%);
    border-radius: 10px;
    background: #ffffff;
  }
  .el-tag {
    margin-bottom: 20px;
  }
}

.evaluate_list /deep/ .el-dialog__body {
  padding: 0 20px 20px;
}
</style>
<style lang="less">
.Scheduling_p {
  line-height: 30px;
}
</style>