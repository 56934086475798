import {
    require
} from '../utils/http'

//修改客服人员信息
function update_staff(data) {
    return require('POST', '/api/user/v1/update_staff/', data)
}
export {
    update_staff
}