import {
    require
} from '../utils/http'

//结算管理列表
function home(data) {
    return require('GET', '/api/data_board/v1/home/', data)
}
// 员工数据看板
function staff_data(data) {
    return require('GET', '/api/data_board/v1/staff_data/', data)
}
// 数据看板详情
function home_info(data) {
    return require('GET', '/api/data_board/v1/home_info/', data)
}
export {
    home,
    staff_data,
    home_info
}