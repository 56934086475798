<template>
  <div v-loading="loading">
    <div class="content">
      <el-button type="warning" plain @click="$router.go(-1)">返回</el-button>
      <div class="details_list">
        <el-table :data="details_list" style="width: 100%">
          <el-table-column prop="resume_name" label="姓名" width="140">
          </el-table-column>
          <el-table-column prop="weixin_gender" label="性别" width="140">
          </el-table-column>
          <el-table-column
            prop="resume_phone"
            label="手机号"
            width="160"
          ></el-table-column
          ><el-table-column prop="id_card_number" label="身份证号" width="220">
          </el-table-column>
          <el-table-column
            prop="contract_salary"
            label="岗位薪资（时薪）"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="clearing_time"
            label="上工时长（小时）"
            width="180"
          >
          </el-table-column
          ><el-table-column
            prop="odd_salary"
            label="合计工资（元）"
            width="180"
          >
          </el-table-column>
          ><el-table-column> </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { c_detail } from "@/api/settlement";
export default {
  data() {
    return {
      loading: false,
      // 详情列表
      details_list: [],
    };
  },
  methods: {
    c_detail(id) {
      c_detail({ jd_id: id }).then((res) => {
        if (!res.code) {
          this.details_list = res.data.clearing_list;
          this.loading = false;
          console.log(this.details_list);
        }
      });
    },
  },
  created() {
    this.loading = true;
    var jd_id = this.$route.query.id;
    this.c_detail(jd_id);
  },
};
</script>

<style lang="less" scoped>
  
</style>