<template>
  <div class="header">
    <div class="company_info">
      <p class="info">
        <!-- <span>123</span> -->
        <i
          style="color: #b5b5b5; font-size: 24px"
          class="iconfont icon-xinxi"
        ></i>
      </p>
      <el-dropdown class="down">
        <template>
          <div class="company_logo">
            <span>{{ companyname }}</span>
            <p>{{ tr_name }}</p>
            <i class="iconfont icon-xiala"></i>
          </div>
        </template>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item @click.native="meClick">我的资料</el-dropdown-item> -->
          <el-dropdown-item @click.native="signOut">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tr_name: "",
      companyname: "",
    };
  },
  methods: {
    // 退出登录
    signOut() {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("tr_id")
      this.$message.success("退出成功");
      this.$router.push({ path: "/" });
    },
  },
  created() {
    this.companyname = sessionStorage.getItem("companyname");
    this.tr_name = sessionStorage.getItem("tr_name");
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: flex-end;
  height: 61px;
  box-shadow: 0 0 5px #dcdcdc;
  position: relative;
  //   top: 0;
  //   left: 180px;
  z-index: 10;
  background: #fff;
}
.company_info {
  width: 91%;
  margin: auto;
  display: flex;
  justify-content: flex-end;
  .info {
    width: 50px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    position: relative;
    cursor: pointer;
    margin-right: 15px;
    span {
      line-height: initial;
      color: #fff;
      position: absolute;
      background-color: #ff0000;
      top: 2px;
      right: 0;
      font-size: 12px;
      border-radius: 20px;
      padding: 2px 4px;
    }
  }
  .company_logo {
    align-items: center;
    height: 61px;
    display: flex;
    p {
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-weight: 400;
      color: #fff;
      border-radius: 4px;
      margin-right: 10px;
      padding: 0 6px;
      font-size: 12px;
      background: #ff8201;
    }
    span {
      display: block;
      line-height: 60px;
      color: #8d92a1;
      margin-right: 15px;
      font-size: 16px;
    }
  }
}
</style>