import axios from "axios"
import router from '../router'


// 请求拦截器
axios.interceptors.request.use(config => {
    // 在发送请求之前做些什么
    let token = sessionStorage.getItem('token')
    if (token) {
        config.headers.token = token
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});



function require(type, url, data) {
    // console.log(url)
    let option = new URLSearchParams()
    if (data instanceof Object) {
        for (var prop in data) {
            if (data[prop] instanceof Object) {
                option.append(prop, JSON.stringify(data[prop]))
            } else {
                option.append(prop, data[prop])

            }

        }
    }
    var ajaxConfig
    if (type.toLowerCase() == 'get') {
        ajaxConfig = {
            method: type,
            url: url,
            params: option,
            proxy: true //支持代理解决跨域
        }
    } else {
        ajaxConfig = {
            method: type,
            url: url,
            data: option,
            proxy: true //支持代理解决跨域
        }
    }
    // console.log(ajaxConfig)
    var promise = new Promise(function (resolve, ) {
        axios(ajaxConfig).then(res => {
            if (res.data.code == 100001 || res.data.code == 100002 || res.data.code == 100003) {
                router.app.$router.push({
                    path: '/'
                })
                return;
            }
            let resData = {}
            if (typeof res == 'string') {
                resData = JSON.parse(decrypt(res, key))
            } else {
                resData = res
            }
            // console.log('请求方式', type, '请求地址', url, '传递数据', option, '解码', resData, )
            resolve(resData.data);
        })
    })
    return promise;
}


export {
    require
}