<template>
  <div class="login">
    <div class="left"></div>
    <div class="right">
      <div class="form">
        <div class="logo">
          <img src="~@/assets/images/main/login_logo.png" alt="" />
        </div>
        <el-form class="form_text">
          <el-form-item>
            <el-input
              oninput="value=value.replace(/[^\d]/g,'')"
              maxlength="11"
              v-model="username"
              placeholder="请输入帐号"
              prefix-icon="el-icon-user"
              style="width: 250px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              v-model="password"
              maxlength="16"
              prefix-icon="el-icon-lock"
              show-password
              @input="password = password.replace(/[^A-Za-z0-9]/g, '')"
              placeholder="请输入密码"
              style="width: 250px"
            ></el-input>
          </el-form-item>
          <el-button @click="onSubmit" class="submit">登录</el-button>
          <el-form-item>
            <p class="copy login_page_copy">
              <el-checkbox v-model="checked"></el-checkbox
              >&nbsp;&nbsp;&nbsp;我同意
              <span style="color: #ff8201;cursor: pointer;" @click="$router.push('/agree')">《用户协议》</span>和
              <span style="color: #ff8201;cursor: pointer;" @click="$router.push('/conceal')">《隐私政策》</span>
            </p>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { require } from "../../utils/http.js";
export default {
  data() {
    return {
      // 账号密码
      username: "",
      password: "",
      // 是否同意
      checked: false,
    };
  },
  methods: {
    //  点击提交
    onSubmit() {
      if (!this.checked) {
        this.$message.warning("请点击同意用户协议和隐私协议");
        return;
      }
      if (!this.username || this.username.length != 11) {
        this.$message.warning("请输入11位用户名");
        return;
      }
      if (!this.password) {
        this.$message.warning("请输入密码");
        return;
      }
      if (this.password.length < 5) {
        this.$message.warning("密码不能低于5位");
        return;
      }
      require("POST", "/api/user/v1/login/", {
        username: this.username,
        password: this.password,
      }).then((res) => {
        if (!res.code) {
          this.$message.success("登录成功");
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("tr_id", res.data.tr_id);
          sessionStorage.setItem("companyname", res.data.name);
          sessionStorage.setItem("agent_id", res.data.agent_id);
          sessionStorage.setItem("tr_name", res.data.tr_name);
          this.$router.push({ path: "/company" });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  background: url(../../assets/images/main/login_bg.png) no-repeat center/100%
    #ff8201;
  // background-size: 100% 100%;
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
}
.left {
  width: 50%;
  height: 100%;
}
.right {
  width: 50%;
  height: 100%;
  //   background-color: #e9eef3;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    background-color: #fff;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 50%;
    border-radius: 5px;
    .logo {
      margin: 40px 30%;
      width: 40%;
    }
    .form_text {
      .submit {
        background-color: #ff8201;
        color: #fff;
        width: 250px;
        margin-bottom: 20px;
      }
      .copy {
        font-size: 12px;
        color: #c6c6c6;
        text-align: center;
      }
    }
  }
}
</style>
<style lang="less">
.login_page_copy .is-checked .el-checkbox__inner {
  background-color: #ff8201 !important;
  border-color: #ff8201 !important;
}
.login_page_copy .el-checkbox__inner {
  border-color: #dcdfe6 !important;
}
</style>