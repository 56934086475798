import {
    require
} from '../utils/http'

//结算管理列表
function c_list(data) {
    return require('GET', '/api/clearing/v1/c_list/', data)
}
// 结算详情列表
function c_detail(data){
    return require('GET', '/api/clearing/v1/c_detail/', data)
}
// 结算
function account(data){
    return require('POST', '/api/clearing/v1/account/', data)
}
export {
    c_list,c_detail,account
}