import {
    require
} from '../utils/http'

// 雷达线索
function radar_list(data) {
    return require('GET', '/api/odd_job_radar/v1/radar_list/', data)
}
// 雷达线索详情
function radar_detail(data){
    return require('GET','/api/odd_job_radar/v1/radar_detail/',data)
}
// 给零工设置标签
function set_tags(data){
    return require('POST','/api/odd_job_radar/v1/set_tags/',data)
}
// 获取地区列表
function get_place(data){
    return require('GET','/api/user/v1/get_place/',data)
}
// 修改零工简历信息
function update_odd_job(data){
    return require('POST','/api/odd_job_radar/v1/update_odd_job/',data)
}
// 查看推送职位
function all_jd_list(data){
    return require('GET','/api/project/v1/all_jd_list/',data)
}
// 推送零工上岗
function push_odd_job(data){
    return require('POST','/api/odd_job_radar/v1/push_odd_job/',data)
}
// 零工报名的职位列表
function get_rs_jd(data){
    return require('GET','/api/odd_job_radar/v1/get_rs_jd/',data)
}
// 客服设置零工沟通状态
function set_status(data){
    return require('POST','/api/odd_job_radar/v1/set_status/',data)
}
// 客服添加零工跟进记录
function set_record(data){
    return require('POST','/api/odd_job_radar/v1/set_record/',data)
}
// 查询零工跟进记录
function get_record(data){
    return require('GET','/api/odd_job_radar/v1/get_record/',data)
}
// 查看零工全部的工作排班时间
function get_odd_all_wt(data){
    return require('GET','/api/odd_job_radar/v1/get_odd_all_wt/',data)
}
//查看客户给零工的评价
function getset_eval(data){
    return require('GET','/api/clearing/v1/set_eval/',data)
}
// 展示城市json
function show_client_place(data){
    return require('GET','/api/odd_job_radar/v1/show_place/',data)
}

export {
    radar_list,radar_detail,set_tags,get_place,update_odd_job,all_jd_list,push_odd_job,get_rs_jd,set_status,set_record,get_record,get_odd_all_wt,getset_eval,show_client_place
}