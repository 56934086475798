export function getPath() {
    return [
        //数据看板
        {
            path: '/company/data_board',
            name: '数据看板',
            isshow: [0, 1, 2, 3, 4],
            nav_id: '1',
        },
        // 项目管理
        {
            path: sessionStorage.getItem('tr_id') == 4 ? '/company/position' : '/company/project',
            name: '项目管理',
            isshow: [0, 1, 2, 3, 4],
            nav_id: '2',
        },
        // 雷达线索
        {
            path: '/company/radar_cue',
            name: '雷达线索',
            isshow: [0, 1, 2, 3],
            nav_id: '3',
        },
        // 认证管理
        {
            path: '/company/authentication',
            name: '认证管理',
            isshow: [0, 1, 2, 3],
            nav_id: '4',
        },

        // // 工资管理
        // {
        //     path: '/company/settlement',
        //     name: '工资管理',
        //     isshow: [0, 1, 2, 3, 4],
        //     nav_id: '5',
        // },

        // 费用管理
        // {
        //     path: '/company/servicecharge',
        //     name: '费用管理',
        //     isshow: [0, 1, 2, 3, 4],
        //     nav_id: '6',
        // },
        // 账号管理
        {
            path: '/company/wallet',
            name: '钱包明细',
            isshow: [0, 1, 2, 3, 4],
            nav_id: '7',
        },
        // 账号管理
        {
            path: '/company/settings',
            name: '账号管理',
            isshow: [2],
            nav_id: '8',
        },
    ]
}