<template>
  <div v-loading="loading">
    <el-button size="mini" @click="onScreen">筛选</el-button>
    <el-dialog
      title="筛选条件"
      :visible.sync="screen"
      class="class_dialogTableVisible"
      @close="dialogClose"
    >
      <el-form class="staff_form" style="max-height: 60vh; overflow: auto">
        <el-form-item class="el_form_item" label="沟通状态">
          <el-select
            class="el_input"
            v-model="screen_list.status"
            placeholder="请选择沟通状态"
          >
            <el-option
              v-for="item in followstatus"
              :key="item.status"
              :label="item.value"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="el_form_item" label="性别">
          <el-select
            class="el_input"
            v-model="screen_list.gender"
            placeholder="请选择性别"
          >
            <el-option
              v-for="item in gender_id"
              :key="item.status"
              :label="item.value"
              :value="item.status"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item class="el_form_item" label="年龄">
          <div class="el_input flex_el_input">
            <el-input
              type="text"
              class="age_fanwei"
              clearable
              v-model="screen_list.age_min"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              placeholder="年纪范围最小值"
              maxlength="2"
            ></el-input>
            <span style="margin: 0 3.2%">——</span>
            <el-input
              type="text"
              class="age_fanwei"
              clearable
              v-model="screen_list.age_max"
              onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
              placeholder="年纪范围最大值"
              maxlength="2"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item class="el_form_item" label="地区">
          <el-input
              type="text"
              clearable
              v-model="screen_list.province"
              placeholder="请输入筛选地区"
            ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="button_footer">
        <div>
          <el-button @click="screen_list = {}" type="warning">清空所有筛选条件</el-button>
        </div>
        <div>
          <el-button @click="screen = false">取 消</el-button>
          <el-button type="primary" @click="screen_queding">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-table :data="data_list" style="width: 100%">
      <el-table-column prop="resume_name" label="姓名" width="120">
        <template slot-scope="scope">
          <span
            @click="details(scope.row)"
            style="color: #409eff; cursor: pointer"
            >{{ scope.row.resume_name }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="标签" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.tags_list">
            <el-tag
              style="margin-right: 5px"
              v-for="(item, index) in scope.row.tags_list.slice(0, 2)"
              :key="index"
              size="mini"
              >{{ item | ellipsis }}</el-tag
            >
            <span v-if="scope.row.tags_list.length > 2">...</span>
          </div>
        </template>
        <template slot-scope="scope">
          <el-popover placement="bottom" trigger="hover">
            <el-tag
              v-for="(item, index) in scope.row.tags_list"
              :key="index"
              disable-transitions
              size="mini"
              style="margin: 2px"
              >{{ item }}</el-tag
            >
            <div slot="reference" class="more">
              <el-tag
                v-for="(item, index) in scope.row.tags_list.slice(0, 2)"
                :key="index"
                disable-transitions
                size="mini"
                style="margin: 2px"
                >{{ item | ellipsis }}</el-tag
              >
              <span v-if="scope.row.tags_list.length > 2">...</span>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="credit_rating" label="信用星级" width="160">
        <template slot-scope="scope">
          <div>
            <el-rate v-model="scope.row.credit_rating" disabled show-score text-color="#ff9900" score-template=""> </el-rate>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="沟通状态" width="120">
        <template slot-scope="scope">{{
          scope.row.status | statusfilter
        }}</template>
      </el-table-column>
      <el-table-column prop="weixin_gender" label="性别" width="100">
        <template slot-scope="scope">
          {{ scope.row.weixin_gender == 1 ? "男" : "女" }}
        </template>
      </el-table-column>
      <el-table-column prop="resume_age" label="年龄" width="100">
      </el-table-column>
      <el-table-column prop="resume_phone" label="联系方式" width="150">
      </el-table-column>
      <el-table-column prop="id_card_number" label="身份证号" width="200">
      </el-table-column>
      <el-table-column prop="location_detail" label="地区" width="190">
      </el-table-column>
      <el-table-column prop="auth_time" label="认证时间" width="140">
      </el-table-column>
      <el-table-column label="健康证审核" width="150" fixed="right">
        <template slot-scope="scope">
          <div v-if="scope.row.health_status == 1">
            <el-button
              @click.stop="examine(scope.row, 0)"
              size="mini"
              type="primary"
              >健康证审核</el-button
            >
          </div>
          <div v-else-if="scope.row.health_status == 2" style="color: #67c23a">
            审核通过
          </div>
          <div v-else-if="scope.row.health_status == 3" style="color: #f56c6c">
            审核拒绝
          </div>
        </template>
      </el-table-column>
      <el-table-column label="学历证/学生证" width="150" fixed="right">
        <template slot-scope="scope">
          <div v-if="scope.row.certificate_status == 1">
            <el-button
              @click.stop="examine(scope.row, 1)"
              size="mini"
              type="primary"
              >学历证/学生证审核</el-button
            >
          </div>
          <div v-else-if="scope.row.certificate_status == 2" style="color: #67c23a">
            审核通过
          </div>
          <div v-else-if="scope.row.certificate_status == 3" style="color: #f56c6c">
            审核拒绝
          </div>
        </template>
      </el-table-column>
      <el-table-column label="信用评级" width="80" fixed="right">
        <template slot-scope="scope">
          <el-popconfirm title="确定将信用评级提升一星吗？" @confirm="Stars(scope.row.elite_id, scope.row.credit_rating)">
            <el-button style="margin-right:10px;" size="mini" :type="scope.row.credit_rating != 5 ? 'primary' : ''" slot="reference">升星</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column label="开通/冻结" width="80" fixed="right">
        <template slot-scope="scope">
          <el-popconfirm :title="scope.row.is_freeze == 1 ? '确定将开通零工账号吗？' : '确定将冻结零工账号吗？'" @confirm="elite_account_management_fun(scope.row.elite_id, scope.row.is_freeze)">
            <el-button size="mini" :type="scope.row.is_freeze == 1 ? 'primary' : 'danger'" slot="reference">{{ scope.row.is_freeze == 1 ? '开通' : '冻结'}}</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <!-- <el-table-column></el-table-column> -->
      <!-- <el-table-column>
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="details(scope.row)"
            >查看详情</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <div style="margin-top: 20px; display: flex; justify-content: center">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="auth_count"
        :page-size="15"
        :current-page="page"
        @current-change="Screen_pagination"
      >
      </el-pagination>
    </div>
    <el-drawer
      @close="handleClick"
      v-loading="vloading"
      title="我是标题"
      size="60%"
      :visible.sync="drawer"
      :with-header="false"
    >
      <div class="drawer" v-if="JSON.stringify(this.radar_detail) != '{}'">
        <div class="left">
          <div class="title">
            <p>详情</p>
            <el-button type="primary" class="bj" @click="edit">{{
              !isedit ? "编辑" : "保存"
            }}</el-button>
          </div>
          <el-form label="left" label-position="left">
            <el-form-item label="姓名：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.name }}
              </div>
              <el-input v-else v-model="Echo.resume_name"></el-input>
            </el-form-item>
            <el-form-item label="性别：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.gender }}
              </div>
              <el-radio-group v-else v-model="Echo.weixin_gender">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="年龄：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.age }}
              </div>
              <el-input v-else v-model="Echo.resume_age"></el-input>
            </el-form-item>
            <el-form-item label="联系方式：">
              <div>
                {{ radar_detail.elite_info.phone }}
              </div>
            </el-form-item>
            <el-form-item label="期望地区：">
              <div v-if="!isedit">
                {{
                  radar_detail.elite_info.place
                    ? radar_detail.elite_info.place
                    : "暂无"
                }}
              </div>
              <el-cascader
                v-else
                v-model="Echo.weixin_province"
                :options="data_place"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址：">
              <div v-if="!isedit">
                {{
                  radar_detail.elite_info.place_detail
                    ? radar_detail.elite_info.place_detail
                    : "暂无"
                }}
              </div>
              <el-input v-else v-model="Echo.weixin_city"></el-input>
            </el-form-item>
            <el-form-item label="到岗时间：">
              <div v-if="!isedit">
                {{ radar_detail.elite_info.work_time_str }}
              </div>
              <div v-else>
                <el-date-picker
                  format="yyyy 年 MM 月 dd 日"
                  value-format="timestamp"
                  v-model="Echo.work_time"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="沟通状态：">
              <el-select
                v-model="followvalue"
                placeholder="请选择"
                @change="switchfollowvalue"
              >
                <el-option
                  v-for="item in followstatus"
                  :key="item.status"
                  :label="item.value"
                  :value="item.status"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="身份证号：">
              <div>
                {{ radar_detail.elite_info.id_card_number }}
              </div>
            </el-form-item>
          </el-form>
          <div class="tags">
            <div class="tags_title">个人标签</div>
            <div v-if="radar_detail.elite_info.resume_tags_list.length != 0">
              <el-tag
                type="success"
                v-for="(item, index) in radar_detail.elite_info
                  .resume_tags_list"
                :key="index"
                >{{ item }}</el-tag
              >
            </div>
            <div v-else>
              <el-tag type="success">暂无标签</el-tag>
            </div>
          </div>

          <div class="tags">
            <div class="tags_title">添加标签(1-6个，每条最多20字)</div>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)"
            >
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              maxlength="20"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >添加标签</el-button
            >
          </div>
          <div class="Timeline">
            <div class="Timeline_title">
              <div>零工浏览 / 报名痕迹</div>
              <div>
                <el-button type="primary" @click="SchedulingDrawerFn()"
                  >查看全部排班</el-button
                >
              </div>
            </div>
            <div
              class="info"
              v-for="(item, index) in radar_detail.radar_list"
              :key="index"
            >
              <div>浏览公司：{{ item.company_name }}</div>
              <div>浏览岗位：{{ !item.jd_name ? "暂无" : item.jd_name }}</div>
              <div class="time">
                <div>
                  工作时间：{{ !item.work_hour ? "暂无" : item.work_hour }}
                </div>
                <div>
                  浏览时间：{{ !item.create_time ? "暂无" : item.create_time }}
                </div>
              </div>
              <div class="img" v-if="item.is_send == 1">
                <img src="~@/assets/images/main/yibaoming.png" alt="" />
              </div>
              <div class="Job_info">
                <div v-if="item.is_odd == 0">
                  <img src="~@/assets/images/main/is_odd0.png" alt="" />
                </div>
                <div v-if="item.is_odd == 2">
                  <img src="~@/assets/images/main/is_odd2.png" alt="" />
                </div>
                <div v-if="item.is_odd == 3">
                  <img src="~@/assets/images/main/is_odd3.png" alt="" />
                </div>
                <div v-if="item.is_odd == 4">
                  <img src="~@/assets/images/main/is_odd4.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <p>跟进记录</p>
          </div>
          <div class="right_input">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="recordInput"
            >
            </el-input>
            <div style="display: flex; justify-content: flex-end">
              <el-button
                style="padding: 6px 20px; margin-top: 10px"
                @click="submit_record"
                type="primary"
                >提交</el-button
              >
            </div>
          </div>
          <div class="recorList">
            <el-timeline :reverse="false">
              <el-timeline-item
                v-for="(item, index) in recordList"
                :key="index"
                :timestamp="item.create_time"
              >
                {{ item.records }}
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <!-- 零工日历 -->
      <el-drawer
        title="零工排班日历"
        :append-to-body="true"
        :before-close="three_handleClose"
        :visible.sync="SchedulingDrawer"
        size="40%"
      >
        <div
          class="calendar All_calendars"
          v-if="JSON.stringify(this_month) != '{}'"
        >
          <div class="timetop">
            <div @click="preTime" style="cursor: pointer">
              <i class="iconfont icon-shangyiye"></i>
            </div>
            <div class="timetop_title">{{ this_month[0].time }}</div>
            <div @click="nextTime" style="cursor: pointer">
              <i class="iconfont icon-xiayiye"></i>
            </div>
          </div>
          <div class="week">
            <div v-for="(item, index) in week" :key="index">{{ item }}</div>
          </div>
          <div>
            <div class="time_list">
              <div
                class="itemlist_div"
                v-for="(item, index) in this_month[0].item_children"
                :key="index"
              >
                <div
                  class="item_div"
                  v-if="
                    Object.keys(isOddSchedulingList).indexOf(
                      item.item_title + '-' + item.item_text
                    ) != -1
                  "
                  @click="timequery(item)"
                  :class="{
                    TimeSelect:
                      dateTime == item.item_title + '-' + item.item_text,
                  }"
                >
                  {{ item.item_text }}
                  <p class="iconfont_wrap"></p>
                </div>
                <div class="old_day" v-else>
                  {{ item.item_text }}
                </div>
                <!-- <i
                  class="iconfont icon-duihao"
                  id="duihao"
                  v-if="dateTime == item.item_title + '-' + item.item_text"
                ></i> -->
              </div>
            </div>
          </div>
        </div>
        <div
          style="margin: 20px auto 0; width: 80%"
          v-if="isOddSchedulingList[this.dateTime] != undefined"
        >
          <div
            class="add_time_list_authentication"
            v-for="(list, dex) in isOddSchedulingList[this.dateTime]"
            :key="dex"
            v-html="OddSchedulingFilter(list)"
          >
            {{ list }}
          </div>
        </div>
        <el-empty v-else description="请选择时间查看排班"></el-empty>
      </el-drawer>
    </el-drawer>

    <el-dialog
      class="examine"
      :title="dialog_title == 0 ? '学历证/学生证审核' : '健康证审核'"
      :visible.sync="isexamine"
      width="35%"
      :before-close="examinecancel"
    >
      <div v-if="dialog_title == 0">
        <div class="examine_img_wrap">
          <span>健康证正面照片：</span>
          <div class="examine_img">
            <el-image
              style="max-height: 50vh"
              :src="examinedata.health_font_url"
              :preview-src-list="examinedata.examine_img"
            >
            </el-image>
          </div>
        </div>

        <div class="examine_text">
          <div>健康证有效期：</div> <div>{{ examinedata.health_end_time }}</div>
        </div>
      </div>
      <div v-else-if="dialog_title == 1">
        <div class="examine_img_wrap">
          <div class="div_span">证件照片：</div>
          <div class="examine_img">
            <el-image
              style="max-height: 50vh"
              :src="examinedata.certificate_pic_url"
              :preview-src-list="examinedata.examine_img"
            >
            </el-image>
          </div>
        </div>
        <div class="examine_text">
          <div>学历：</div> <div>{{ examinedata.education_background }}</div>
        </div>
        <div class="examine_text">
          <div>院校：</div> <div>{{ examinedata.graduate_school }}</div>
        </div>
        <div class="examine_text">
          <div>擅长学科：</div> <div>{{ examinedata.skilled_course }}</div>
        </div>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="examinereject">驳 回</el-button>
        <el-button type="primary" @click="examineok">通 过</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Vue from "vue";
// 零工排班日历列表内容过滤
Vue.prototype.OddSchedulingFilter = function (value) {
  return `<div style="width:65%;">公司名称：${value.company}</div><div style="width:35%;">职位：${value.jd_name}</div><div style="width:65%;">品牌名：${value.company_short_name}</div><div style="width:35%;">工作时间：${value.work_up}-${value.work_down}</div>`;
};
import { auth_list, audit_health, audit_certificate, alter_rating, elite_account_management } from "@/api/authentication";
import {
  radar_detail,
  set_tags,
  get_place,
  update_odd_job,
  set_status,
  set_record,
  get_record,
  get_odd_all_wt,
} from "@/api/radar_cue";
import { p_list } from "@/api/project";
export default {
  data() {
    return {
      screen: false, //筛选显示隐藏
      loading: true,
      vloading: false,
      // 审核是否显示
      isexamine: false,
      // 总条数
      auth_count: 0,
      //  列表
      data_list: [],
      //   页码
      page: 1,
      // 筛选的内容
      screen_list: {},
      new_screen_list: {},
      // 员工ID
      elite_id: "",
      // 审核时的健康证照片和到期时间
      examinedata: {},
      //   抽屉显示
      drawer: false,
      // 跟进状态
      followvalue: "0",
      followstatus: [
        {
          status: "0",
          value: "未拨打",
        },
        {
          status: "1",
          value: "已接通",
        },
        {
          status: "2",
          value: "未接通",
        },
        {
          status: "3",
          value: "号码无效",
        },
        {
          status: "4",
          value: "不合适",
        },
      ],
      gender_id: [
        {
          status: "0",
          value: "男女不限",
        },
        {
          status: "1",
          value: "男",
        },
        {
          status: "2",
          value: "女",
        },
      ],
      // 跟进记录
      recordInput: "",
      recordList: [],
      // 线索详情
      radar_detail: {},
      // tag标签
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      // 是否为编辑
      isedit: false,
      edit_list: [],
      // 回显的数据
      Echo: {},
      // 地点列表
      data_place: {},

      // 零工排班抽屉显示
      SchedulingDrawer: false,

      isOddSchedulingList: {},

      //当前年月日
      isyear: "",
      ismonth: "",
      isday: "",

      // 排班的时间
      SchedulingTime: {},
      // 旧的
      week: ["日", "一", "二", "三", "四", "五", "六"],
      time_frame: "", // 时间范围
      days: "", // 周的下标
      dateTime: [], // 日期时间
      // 新的
      Warehouse: {}, // 仓库
      this_month: {}, // 当前月份
      item_children: [],
      
      dialog_title: 0, // 审核标题
    };
  },
  filters: {
    // 更新时间过滤
    create_timeFilters(val) {
      return val.split(" ")[0];
    },
    // 列表沟通状态过滤
    statusfilter(value) {
      switch (value) {
        case 0:
          return `未拨打`;
          break;
        case 1:
          return `已接通`;
          break;
        case 2:
          return `未接通`;
          break;
        case 3:
          return `号码无效`;
          break;
        case 4:
          return `不合适`;
          break;
      }
      return value;
    },
    // 标签多余4个字的过滤
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 6) {
        return value.slice(0, 6) + "...";
      }
      return value;
    },
  },
  methods: {
    // 点击筛选
    onScreen() {
      this.screen = true;
      if (JSON.stringify(this.new_screen_list) == "{}") {
        return;
      } else {
        this.screen_list = JSON.parse(JSON.stringify(this.new_screen_list));
      }
    },
    //确定筛选
    screen_queding() {
      this.new_screen_list = JSON.parse(JSON.stringify(this.screen_list));
      this.screen_list = {};
      this.page = 1;
      this.screen = false;
      this.get_list();
    },
    // 翻页
    Screen_pagination(val) {
      this.page = val;
      this.get_list();
    },
    // 关闭时执行
    dialogClose() {
      console.log("关闭了");
      this.screen_list = {};
    },
    // 点击排班日历显示
    SchedulingDrawerFn() {
      this.SchedulingDrawer = true;
      this.formatTime();
      this.current_time_();
      this.get_odd_all_wt();
    },
    // 零工排班详情抽屉
    three_handleClose(done) {
      done();
      this.dateTime = [];
    },
    // 查看零工 工作排班时间
    get_odd_all_wt() {
      // this.vvloading = true;
      var obj = {
        elite_id: this.elite_id,
        year: this.isyear,
        month: this.ismonth,
      };
      get_odd_all_wt(obj).then((res) => {
        if (!res.code) {
          this.isOddSchedulingList = res.data.arrange_work;
          // this.vvloading = false;
          console.log(this.isOddSchedulingList);
        }
      });
    },
    // 健康证审核
    examine(row, idx) {
      this.dialog_title = idx
      if(idx == 0){
        this.examinedata = {
          health_font_url: row.health_font_url,
          health_end_time: row.health_end_time,
          examine_img: [row.health_font_url],
          elite_id: row.elite_id,
        };
      }else{
        this.examinedata = {
          examine_img: [row.certificate_pic_url],
          certificate_pic_url: row.certificate_pic_url,
          education_background: row.education_background,
          graduate_school: row.graduate_school,
          skilled_course: row.skilled_course,
          elite_id: row.elite_id,
        };
      }


      this.isexamine = true;
    },
    // 取消审核
    examinecancel() {
      this.isexamine = false;
    },
    // 驳回审核
    examinereject() {
      if(this.dialog_title == 0){
        this.audit_health(this.examinedata.elite_id, 3);
      }else{
        this.audit_certificate(this.examinedata.elite_id, 3)
      }
    },
    // 通过审核
    examineok() {
      if(this.dialog_title == 0){
        this.audit_health(this.examinedata.elite_id, 2);
      }else{
        this.audit_certificate(this.examinedata.elite_id, 2)
      }
    },
    // 审核健康证
    audit_health(elite_id, audit_status) {
      this.loading = true;
      audit_health({ elite_id, audit_status }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.get_list();
        this.loading = false;
        this.isexamine = false;
      });
    },
    // 审核证书
    audit_certificate(elite_id, audit_status) {
      this.loading = true;
      audit_certificate({ elite_id, audit_status }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.get_list();
        this.loading = false;
        this.isexamine = false;
      });

    },
    // 抽屉关闭执行
    handleClick() {
      this.get_list();
      this.radar_detail = {};
      this.isedit = false;
    },
    //yyyy-mm-dd格式日期
    formatDayTime(val) {
      if (val) {
        let date = new Date(val);
        let Y = date.getFullYear();
        let M = date.getMonth() + 1;
        let D = date.getDate();

        if (M < 10) {
          M = "0" + M;
        }
        if (D < 10) {
          D = "0" + D;
        }

        return Y + "-" + M + "-" + D;
      } else {
        return "";
      }
    },

    // 获取我的项目列表
    p_list() {
      p_list().then((res) => {
        if (!res.code) {
          this.project_list = res.data.project_list;
          console.log(this.project_list);
        }
      });
    },

    // 地点选中
    handleChange(value) {
      console.log(value);
    },
    // 编辑
    edit() {
      if (!this.isedit) {
        //回显
        this.Echo = {
          resume_phone: this.radar_detail.elite_info.phone,
          resume_name: this.radar_detail.elite_info.name,
          resume_age: this.radar_detail.elite_info.age,
          weixin_gender: this.radar_detail.elite_info.gender == "男" ? 1 : 2,
          weixin_province: this.radar_detail.elite_info.place_id_list,
          work_time:
            this.radar_detail.elite_info.work_time != 0
              ? this.radar_detail.elite_info.work_time * 1000
              : "",
          weixin_city: this.radar_detail.elite_info.place_detail,
        };
        console.log(this.Echo);
        this.isedit = true;
      } else {
        this.vloading = true;
        var obj = {
          elite_id: this.elite_id,
          odd_job_data: {
            resume_phone: this.Echo.resume_phone,
            resume_name: this.Echo.resume_name,
            resume_age: this.Echo.resume_age,
            weixin_gender: this.Echo.weixin_gender,
            weixin_province: this.Echo.weixin_province[2],
            work_time: this.Echo.work_time / 1000,
            weixin_city: this.Echo.weixin_city,
          },
        };
        update_odd_job(obj).then((res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.isedit = false;
            this.radar_detailFn();
          }
        });
        console.log(obj);
      }
    },
    // tag标签
    handleClose(tag) {
      // 删除tag标签
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      var arrJoinString = this.dynamicTags.join();
      set_tags({ elite_id: this.elite_id, tags: arrJoinString }).then((res) => {
        if (!res.code) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 点击添加的时候执行
    showInput() {
      if (this.dynamicTags != undefined && this.dynamicTags.length >= 6) {
        this.$message({
          message: "职位技能只能添加6个",
          type: "warning",
        });
      } else {
        this.inputVisible = true;
        this.$nextTick((_) => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      }
    },
    // 确认添加的时候执行
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        console.log(this);
        this.dynamicTags.push(inputValue);
        var arrJoinString = this.dynamicTags.join();
        set_tags({ elite_id: this.elite_id, tags: arrJoinString }).then(
          (res) => {
            if (!res.code) {
              this.$message.success(res.msg);
            } else {
              this.$message.error(res.msg);
            }
          }
        );
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    // 获取列表
    get_list() {
      this.loading = true;
      var obj = {
        page: this.page - 1,
        status: this.new_screen_list.status ? this.new_screen_list.status : "",
        gender: this.new_screen_list.gender ? this.new_screen_list.gender : "",
        min_age: this.new_screen_list.age_min
          ? this.new_screen_list.age_min
          : "",
        max_age: this.new_screen_list.age_max
          ? this.new_screen_list.age_max
          : "",
        location_detail: this.new_screen_list.province
          ? this.new_screen_list.province
          : "",
      };
      auth_list(obj).then((res) => {
        if (!res.code) {
          this.data_list = res.data.auth_list;
          this.auth_count = res.data.auth_count;
          this.loading = false;
        }
      });
    },
    details(row) {
      // row 数据
      this.drawer = true;
      //   调取列表接口详情
      this.vloading = true;
      // 赋值ID
      this.elite_id = row.elite_id;
      this.radar_detailFn();
      this.get_record();
    },
    // 雷达线索详情接口调用
    radar_detailFn() {
      radar_detail({ elite_id: this.elite_id }).then((res) => {
        if (!res.code) {
          this.vloading = false;
          this.radar_detail = res.data;
          this.followvalue = String(res.data.elite_info.status);
          console.log(this.followvalue);
          if (res.data.elite_info.tags_list != undefined) {
            this.dynamicTags = res.data.elite_info.tags_list;
          } else {
            this.dynamicTags = [];
          }
          // console.log(this.dynamicTags);
          // console.log(this.radar_detail);
        }
      });
    },
    // 跟进状态切换
    switchfollowvalue() {
      set_status({ elite_id: this.elite_id, status: this.followvalue }).then(
        (res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.get_record();
          }
        }
      );
    },
    // 添加跟进记录
    submit_record() {
      var new_change = this.recordInput.replace(/\s*/g, "");
      if (new_change == "") {
        this.$message.error("请输入跟进记录");
        return;
      }
      set_record({ elite_id: this.elite_id, record: this.recordInput }).then(
        (res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.recordInput = "";
            this.get_record();
          }
        }
      );
    },
    // 查询跟进记录
    get_record() {
      get_record({ elite_id: this.elite_id }).then((res) => {
        if (!res.code) {
          this.recordList = res.data.records_list;
          console.log(this.recordList);
        }
      });
    },
    // 获取地区列表
    get_place() {
      get_place({ unlimited: 0 }).then((res) => {
        if (!res.code) {
          this.data_place = res.data;
          // console.log(this.data_place);
        }
      });
    },
    // 数组去重
    unique(arr) {
      var newArr = [];
      for (var i = 0; i < arr.length; i++) {
        if (newArr.indexOf(arr[i]) === -1) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },

    // // // 时间
    // 格式化时间
    formatTime() {
      let date = new Date();
      this.isyear = date.getFullYear();
      this.ismonth = date.getMonth() + 1;
      this.isday = date.getDate();
    },
    // 日历表
    current_time_() {
      let dateTime, this_month, Last_month, next_month;
      let yy = this.isyear;
      let mm = this.ismonth;
      let dd = this.isday;
      dateTime = [`${yy}-${mm < 10 ? "0" + mm : mm}-${dd}`];
      this_month = this.obtain_time_(yy, mm);
      Last_month = this.obtain_time_(
        mm != 1 ? yy : yy - 1,
        mm != 1 ? mm - 1 : 12
      );
      next_month = this.obtain_time_(
        mm != 12 ? yy : yy + 1,
        mm != 12 ? mm + 1 : 1
      );
      console.log(Last_month);
      this.this_month = this_month;
      // console.log(Last_month, this_month, next_month);
      // console.log(this_month);
      // console.log(this.dateTime.length);
      // if (this.dateTime.length == 0) {
      //   this.dateTime = dateTime;
      // }
    },
    // 获取 七天数据
    obtain_time_(yy, mm) {
      yy = Number(yy);
      mm = Number(mm);
      let item_list = [
        {
          time: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_children: [],
        },
      ]; // 返回数据
      if (this.Warehouse[`${yy}-${mm}`]) {
        item_list = this.Warehouse[`${yy}-${mm}`];
      } else {
        let dd = this.mGetDate_(yy, mm), // 获取当前月多少天
          last_days = this.days_fun(`${yy}-${mm}-1`), // 本月的第一天是周几
          next_days = this.days_fun(`${yy}-${mm}-${dd}`), // 本月的最后一天是周几
          last_list = [], // 返回数据
          next_list = []; // 返回数据
        item_list[0].item_children = this.item_list_fun(yy, mm, dd); // 获取当前所有时间
        if (last_days != 0) {
          // 第一天不是周日
          last_list = this.last_days_fun(
            mm != 1 ? yy : yy - 1,
            mm != 1 ? mm - 1 : 12,
            last_days
          );
        }
        if (next_days != 6) {
          // 最后一天不是周六
          next_list = this.next_days_fun(
            mm != 12 ? yy : yy + 1,
            mm != 12 ? mm + 1 : 1,
            next_days
          );
        }
        item_list[0].item_children = [
          ...last_list,
          ...item_list[0].item_children,
          ...next_list,
        ];

        this.Warehouse[`${yy}-${mm}`] = item_list;
      }
      return item_list;
    },
    // 当前日历本月数据
    item_list_fun(yy, mm, dd) {
      let item_children = [];
      for (let index = 1; index <= dd; index++) {
        item_children.push({
          item_title: `${yy}-${mm < 10 ? "0" + mm : mm}`,
          item_text: index,
        });
      }
      return item_children;
    },
    // 当前日历展示第一行数据
    last_days_fun(yy, mm, days) {
      let item_children = [],
        Last_month = this.mGetDate_(yy, mm); // 上一月多少天
      for (let index = 0; index < days; index++) {
        item_children.unshift("");
      }
      // console.log(item_children);
      return item_children;
    },
    // 当前日历展示最后一行数据
    next_days_fun(yy, mm, days) {
      let item_children = [];
      for (let index = 1; index < 7 - days; index++) {
        item_children.push("");
      }
      return item_children;
    },
    // 计算当前日期
    Calculation_date_(yy, mm, dd, num) {
      let mGetDate = this.mGetDate_(yy, mm), // 当前月多少天
        Last_month = this.mGetDate_(
          mm != 1 ? yy : yy - 1,
          mm != 1 ? mm - 1 : 12
        ), // 上一月多少天
        new_mm = false,
        new_dd = false;
      if (num) {
        new_dd = dd + 7 > mGetDate ? true : false;
        dd = new_dd ? dd + 7 - mGetDate : dd + 7;
        if (new_dd) {
          new_mm = mm + 1 > 12 ? true : false;
          mm = new_mm ? 1 : mm + 1;
        }
        yy = new_mm ? yy + 1 : yy;
        // console.log(yy, mm, dd, num)
      } else {
        new_dd = dd - 7 < 1 ? true : false;
        dd = new_dd ? Last_month + (dd - 7) : dd - 7;
        if (new_dd) {
          new_mm = mm - 1 < 1 ? true : false;
          mm = new_mm ? 12 : mm - 1;
        }
        yy = new_mm ? yy - 1 : yy;
      }
      return `${yy}-${mm}-${dd}`;
    },
    // 本月多少天
    mGetDate_(year, month) {
      var d = new Date(year, month, 0);
      return d.getDate();
    },
    // 周几
    days_fun(e) {
      return new Date(e).getDay();
    },
    // 上一个月
    preTime() {
      if (this.ismonth == 1) {
        this.isyear--;
        this.ismonth = 12;
      } else {
        this.ismonth--;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
    // 下一个月
    nextTime() {
      if (this.ismonth == 12) {
        this.isyear++;
        this.ismonth = 1;
      } else {
        this.ismonth++;
      }
      this.isday = 1;
      this.current_time_();
      if (this.SchedulingDrawer) {
        this.get_odd_all_wt();
      }
      // console.log(this.isyear, this.ismonth);
    },
    // 排班时间点击日期
    timequery(e) {
      this.dateTime = e.item_title + "-" + e.item_text;
    },

    // 星级
    Stars(e, credit_rating) {
      if(credit_rating == 5){
        this.$message.success('当前已是最高评级');
        return
      }
      alter_rating({elite_id: e}).then(res=>{
        if(!res.code){
            this.$message.success(res.msg);
            this.get_list();
        }else{
            this.$message.error(res.msg);
        }
      })
      console.log(e)
    },
    // 开通/冻结
    elite_account_management_fun(e, is_freeze) {
      console.log('is_freeze', is_freeze)
      // return false
      elite_account_management({elite_id: e, is_freeze: is_freeze == 0 ? 1 : 0}).then(res=>{
        if(!res.code){
            this.$message.success(res.msg);
            this.get_list();
        }else{
            this.$message.error(res.msg);
        }
      })
      console.log(e)
    },
  },

  created() {
    this.get_list();
    this.get_place();
    sessionStorage.setItem("nav_id", 4);
  },


};
</script>

<style lang="less" scoped>
.drawer {
  display: flex;
  flex-wrap: wrap;
  .el-form /deep/ .el-form-item__label {
    width: 85px;
  }
  > .left {
    width: 65%;
    box-sizing: border-box;
    padding: 20px;
    border-right: 1px solid #eee;
  }
  > .right {
    box-sizing: border-box;
    padding: 20px;
    width: 35%;
    .right_input {
      margin-top: 20px;
    }
    .recorList {
      margin-top: 20px;
      .el-timeline {
        padding: 0;
      }
    }
  }
  .title {
    font-size: 20px;
    // font-weight: bold;
    display: flex;
    justify-content: space-between;
    .bj {
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
    }
  }
}
.el-form {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.el-form .el-form-item {
  width: 50%;
}
.el-form /deep/ .el-form-item__label {
  width: 100px;
}
.tags {
  margin-bottom: 30px;
}
.tags .tags_title {
  margin-bottom: 20px;
}
.tags .el-tag {
  margin-right: 15px;
  margin-bottom: 20px;
}
.el-form-item /deep/ .el-input {
  width: 70%;
}
.el-cascader {
  width: 70%;
}
.el-cascader /deep/ .el-input {
  width: 100% !important;
}
.Timeline_title {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 20px;
  font-size: 20px;
  // font-weight: bold;
}
.Timeline .Job_info {
  position: absolute;
  right: 16%;
  bottom: 20%;
  width: 80px;
  height: 80px;
  div {
    width: 100%;
    height: 100%;
  }
}
.Timeline .info {
  margin: 0;
  // width: 60%;
  display: flex;
  border-bottom: 1px solid #aaa;
  line-height: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 20px 20px;
  position: relative;
  .img {
    position: absolute;
    right: -20px;
    top: -20px;
    height: 80px;
    width: 80px;
  }
  div {
    margin-right: 20px;
  }
  .time {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
.add_time_wrap {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  .el-date-editor.el-input {
    margin-right: 20px;
  }
}
.examine /deep/ .el-dialog__header {
  text-align: center;
}
.examine /deep/ .el-dialog__footer {
  text-align: center !important;
}
.examine {
  .examine_img_wrap {
    display: flex;
    .div_span {
      width: 120px;
      font-size: 18px;
    }
    .examine_img {
      width: 400px;
    }
  }
  .examine_text {
    display: flex;
    font-size: 18px;
    margin-top: 20px;
    line-height: 24px;
    box-sizing: border-box;
    // span {
    //   width: 144px;
    // }
    div:nth-child(1){
      width: 120px;
    }
    div:nth-child(2){
      flex: 1;
    }
  }
}

.calendar {
  width: 428px;
  margin: 0 auto;
  .timetop {
    display: flex;
    justify-content: center;
    .timetop_title {
      padding: 0 40px;
    }
  }
  .week {
    display: flex;
    flex-wrap: wrap;
    div {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      width: 12%;
      margin: 1%;
    }
  }
  .time_list {
    border-left: 1px solid #eee;
    display: flex;
    flex-wrap: wrap;
    .itemlist_div {
      box-sizing: border-box;
      text-align: center;
      width: 60px;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      position: relative;
      cursor: pointer;
      .iconfont {
        position: absolute;
        top: 3px;
        right: 3px;
      }
      .item_div {
        border: 1px solid #ff8201 !important;
      }
      &:nth-child(-n + 7) {
        border-top: 1px solid #eee;
      }
      .old_day {
        color: #999;
      }
      .old_day:hover {
        cursor: not-allowed;
        border: 1px solid #fff;
      }
      .active_day {
        border: 1px solid #eee;
        background-color: #eee;
      }
      div {
        border: 1px solid #fff;
        line-height: 45px;
      }
      div:hover {
        border: 1px solid #efefef;
      }
      .no_class {
        cursor: default;
      }
      .no_class:hover {
        border: 3px solid #fff;
      }
    }
  }
  .TimeSelect {
    border: 1px solid #ff8201 !important;
    background-color: #ff8201;
    color: #fff;
    position: relative;
    .iconfont_wrap {
      display: block !important;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #000;
    }
  }
  .iconfont_wrap {
    display: none;
  }
  .oldSelect {
    border: 3px solid #ff8201 !important;
    background-color: #ff8201;
    color: #fff;
  }

  .calendar_time_list {
    div {
      position: relative;
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
      padding: 4px 24px;
      line-height: 25px;
      margin: 10px 0;
    }
    i {
      position: absolute;
      right: 10px;
      top: 2px;
      cursor: pointer;
    }
  }
}
.staff_form /deep/ .el-form-item {
  width: 90%;
}
.staff_form /deep/ .el-form-item__label {
  width: 100%;
  text-align: left;
}
.staff_form /deep/ .el-select {
  width: 100%;
}
.staff_form /deep/ .el-input {
  width: 100%;
}
.staff_form /deep/ .el-cascader {
  width: 100% !important;
}
.age_fanwei {
  width: 45% !important;
}
.button_footer{
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="less">
.add_time_list_authentication {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #cdcdcd;
  padding: 10px 10px;
  div {
    font-size: 14px;
    line-height: 30px;
  }
  &:last-child {
    border: none;
  }
}
</style>