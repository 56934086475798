<template>
  <div v-loading="loading">
    <el-button type="warning" plain @click="$router.go(-1)">返回</el-button>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="update_time" label="支付时间" width="180">
        </el-table-column>
        <el-table-column prop="company_short_name" label="品牌名" width="180">
        </el-table-column>
        <el-table-column prop="jd_title" label="岗位名称" width="180">
        </el-table-column>
        <el-table-column prop="jd_people_min" label="用工人数" width="100">
        </el-table-column>
        <el-table-column label="工作日期" width="260">
          <template slot-scope="scope">
            <div v-html="time_filter(scope.row.work_day)"></div>
          </template>
        </el-table-column>
        <el-table-column prop="work_hour" label="工作时间段" width="110">
        </el-table-column>
        <el-table-column label="总工时" width="90">
          <template slot-scope="scope">
            {{ scope.row.all_work_hours }} 小时
          </template>
        </el-table-column>
        <el-table-column label="岗位时薪" width="90">
          <template slot-scope="scope">
            <div>{{ scope.row.jd_salary_min }}元/小时</div>
          </template>
        </el-table-column>
        <el-table-column label="总费用" width="110">
          <template slot-scope="scope">
            {{ scope.row.all_jd_salary }} 元
          </template>
        </el-table-column>
        <el-table-column label="工资部分" width="110">
          <template slot-scope="scope">
            {{ scope.row.odd_salary }} 元
          </template>
        </el-table-column>
        <el-table-column label="服务费部分" width="110">
          <template slot-scope="scope">
            {{ scope.row.service_charge }} 元
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="jd_count"
        @current-change="TurnPages"
        :page-size="15"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { cost_management } from "@/api/project";
Vue.prototype.time_filter = function (value) {
  let time = value.split(",");
  let length = time.length;
  if (length > 1) {
    return (
      time[0].split("-")[0] +
      "年" +
      time[0].split("-")[1] +
      "月" +
      time[0].split("-")[2] +
      "日" +
      " - " +
      time[length - 1].split("-")[0] +
      "年" +
      time[length - 1].split("-")[1] +
      "月" +
      time[length - 1].split("-")[2] +
      "日"
    );
  } else {
    return (
      time[0].split("-")[0] +
      "年" +
      time[0].split("-")[1] +
      "月" +
      time[0].split("-")[2] +
      "日"
    );
  }
};
export default {
  data() {
    return {
      loading: false,
      // 总数量
      jd_count: 0,
      // 页码
      page: 0,
      // 列表
      list: [],
    };
  },
  methods: {
    // 翻页
    TurnPages(val) {
      this.page = val - 1;
      this.cost_management();
    },
    // 列表接口
    cost_management() {
      this.loading = true;
      cost_management({ page: this.page, project_id: this.project_id }).then(
        (res) => {
          if (!res.code) {
            this.jd_count = res.data.jd_count;
            console.log(this.jd_count);
            this.list = res.data.jd_list;
            this.loading = false;
          }
        }
      );
    },
  },
  created() {
    this.project_id = this.$route.query.id;
    this.cost_management();
  },
};
</script>

<style lang="less" scoped>
.pagination {
  margin: 40px 0 30px;
  display: flex;
  justify-content: center;
}
</style>