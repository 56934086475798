import {
    require
} from '../utils/http'

//结算管理列表
function auth_list(data) {
    return require('GET', '/api/auth/v1/auth_list/', data)
}
//健康证审核
function audit_health(data) {
    return require('POST', '/api/auth/v1/audit_health/', data)
}
//证书审核
function audit_certificate(data) {
    return require('POST', '/api/auth/v1/audit_certificate/', data)
}

//信用星级修改
function alter_rating(data) {
    return require('POST', '/api/project/v1/alter_rating/', data)
}

//信用星级修改
function elite_account_management(data) {
    return require('POST', '/api/project/v1/elite_account_management/', data)
}



export {
    auth_list,audit_health,audit_certificate, alter_rating, elite_account_management
}