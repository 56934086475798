<template>
  <div class="sett_wrap" v-loading="loading">
    <div class="add">
      <div class="kf_title">客服列表</div>
      <el-button type="primary" @click="addaccount">添加账号</el-button>
    </div>
    <div class="kf_list">
      <el-table :data="kf_list" style="width: 100%">
        <el-table-column prop="name" label="姓名" width="230">
        </el-table-column>
        <el-table-column prop="username" label="账号" width="230">
        </el-table-column>
        <el-table-column width="130" label="权限"
          ><template>客服</template></el-table-column
        >
        <el-table-column label="操作" align="center" width="300">
          <template slot-scope="scope">
            <el-button @click="Modifyinfo(scope.row)" type="text" size="small"
              >修改信息</el-button
            >
            <el-button
              @click="Modifypassword(scope.row)"
              type="text"
              size="small"
              >修改密码</el-button
            >
            <el-button @click="deleteinfo(scope.row)" type="text" size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
        <el-table-column></el-table-column>
      </el-table>
    </div>
    <div class="eject" @click.self="ishide" v-if="isShow">
      <el-form @click.stop>
        <!-- 添加账号 -->
        <div class="list_wrap" v-if="isaccount">
          <div class="list title">客服账号创建</div>
          <div class="list">
            <el-form-item label="账号：">
              <el-input
                v-model="username"
                placeholder="请输入账号"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码：">
              <el-input
                v-model="password"
                @input="password = password.replace(/[^A-Za-z0-9]/g, '')"
                placeholder="请输入密码"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </div>
          <div class="list">
            <el-form-item label="姓名：">
              <el-input v-model.trim="name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <!-- <el-form-item label="代理商ID：">
            <el-input v-model="agent_id" disabled></el-input>
          </el-form-item> -->
            <el-form-item label="权限：">
              <el-input value="客服" disabled></el-input>
            </el-form-item>
          </div>
          <div class="list"></div>
          <div class="list button">
            <el-form-item>
              <el-button type="primary" @click="onSubmit">立即创建</el-button>
            </el-form-item>
          </div>
        </div>
        <!-- 客服信息修改 -->
        <div class="list_wrap" v-if="accountinfo">
          <div class="list title">客服信息修改</div>
          <div class="list">
            <el-form-item label="账号：">
              <el-input
                v-model="username"
                placeholder="请输入账号"
                oninput="value=value.replace(/[^\d]/g,'')"
                @change="update_data.username = username"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="姓名：">
              <el-input
                v-model.trim="name"
                placeholder="请输入姓名"
                @change="update_data.name = name"
              ></el-input>
            </el-form-item>
          </div>
          <div class="list">
            <el-form-item label="权限：">
              <el-input value="客服" disabled></el-input>
            </el-form-item>
          </div>
          <div class="list"></div>
          <div class="list button">
            <el-form-item>
              <el-button type="primary" @click="onModify">修改</el-button>
            </el-form-item>
          </div>
        </div>
        <!-- 密码修改 -->
        <div class="list_wrap" v-if="ispassword">
          <div class="list title">密码修改</div>
          <div class="list">
            <el-form-item label="密码：">
              <el-input
                v-model="password"
                @input="password = password.replace(/[^A-Za-z0-9]/g, '')"
                placeholder="请输入密码"
                @change="update_data.password = password"
                maxlength="16"
              ></el-input>
            </el-form-item>
          </div>
          <div class="list button">
            <el-form-item>
              <el-button type="primary" @click="onModifypassword"
                >修改</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { require } from "../../utils/http.js";
import { get_staff } from "@/api/project";
import { update_staff } from "@/api/settings";

export default {
  data() {
    return {
      loading: true,
      username: "",
      password: "",
      name: "",
      agent_id: "",
      tr_id: 3,
      // id
      user_id: "",
      // 客服列表
      kf_list: [],
      // 外框是否显示
      isShow: false,
      // 创建账号是否显示
      isaccount: false,
      // 修改账号信息是否显示
      accountinfo: false,
      // 修改密码是否显示
      ispassword: false,
      // 上传的数据
      update_data: {},
    };
  },
  methods: {
    // 添加账号显示
    addaccount() {
      this.isShow = true;
      this.isaccount = true;
    },
    // 点击隐藏
    ishide() {
      this.initialization();
    },
    // 修改信息
    Modifyinfo(row) {
      this.username = row.username;
      this.name = row.name;
      this.user_id = row.user_id;
      this.isShow = true;
      this.accountinfo = true;
      console.log(this.update_data);
    },
    // 点击修改信息
    onModify() {
      if (this.update_data.username != null && this.username.length != 11) {
        this.$message.warning("请输入11位用户名");
        return;
      }
      if (this.update_data.name != null && this.update_data.name.length == 0) {
        this.$message.warning("请输入姓名");
        return;
      }
      update_staff({
        user_id: this.user_id,
        update_data: this.update_data,
      }).then((res) => {
        if (!res.code) {
          this.$message.success("修改成功");
          this.initialization();
          this.get_staff();
        }else if(res.code == 202002){
          this.$message.error(res.msg)
        }
      });
    },
    // 修改密码
    Modifypassword(row) {
      this.user_id = row.user_id;
      this.isShow = true;
      this.ispassword = true;
    },
    // 点击修改密码
    onModifypassword() {
      if (
        this.update_data.password == undefined ||
        this.update_data.password.length < 5
      ) {
        this.$message.warning("密码不能低于5位");
        return;
      }
      update_staff({
        user_id: this.user_id,
        update_data: this.update_data,
      }).then((res) => {
        if (!res.code) {
          this.$message.success("修改成功");
          this.initialization();
          this.get_staff();
        }
      });
    },
    //删除客服账号
    deleteinfo(row) {
      update_staff({
        user_id:row.user_id,
        update_data:{is_del:1}
      }).then((res) => {
        if (!res.code) {
          this.$message.success("删除成功");
          this.get_staff();
        }
      });
    },
    // 提交
    onSubmit() {
      if (!this.username || this.username.length != 11) {
        this.$message.warning("请输入11位用户名");
        return;
      }
      if (!this.password) {
        this.$message.warning("请输入密码");
        return;
      }
      if (this.password.length < 5) {
        this.$message.warning("密码不能低于5位");
        return;
      }
      if (this.name == "") {
        this.$message.warning("请输入姓名");
        return;
      }
      var register_data = {
        username: this.username,
        password: this.password,
        agent_id: this.agent_id,
        name: this.name,
        tr_id: this.tr_id,
      };
      this.loading = true;
      this.register(register_data);
    },
    // 注册
    register(register_data) {
      require("POST", "/api/user/v1/register/", { register_data }).then(
        (res) => {
          if (!res.code) {
            this.$message.success(res.msg);
            this.initialization();
            this.get_staff();
          } else {
            this.$message.error(res.msg);
            this.loading = false;
          }
        }
      );
    },
    // 数据初始化
    initialization() {
      this.isShow = false;
      this.isaccount = false;
      this.accountinfo = false;
      this.ispassword = false;
      this.username = "";
      this.password = "";
      this.name = "";
      this.update_data = {};
      this.user_id = "";
    },
    // 获取客服列表
    get_staff() {
      this.loading = true;
      get_staff().then((res) => {
        if (!res.code) {
          this.kf_list = res.data.staff_list;
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.agent_id = sessionStorage.getItem("agent_id");
    this.get_staff();
    sessionStorage.setItem('nav_id',7)
  },
};
</script>

<style lang="less" scoped>
.add {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .kf_title {
    font-size: 30px;
    font-weight: bold;
  }
}
.sett_wrap {
  // padding-top: 130px;
  padding-bottom: 150px;
}
.list {
  display: flex;
}
.title {
  margin-bottom: 50px;
  font-size: 30px;
  display: flex;
  justify-content: center;
}
.content {
  text-align: center;
}
.list /deep/ .el-form-item {
  margin-right: 20px;
  display: flex;
}
.list /deep/ .el-form-item__label {
  // width: 100px;
}
.el-form {
  display: flex;
  justify-content: center;
}
.el-form /deep/ .button {
  display: flex;
  justify-content: center;
}
.eject {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 99;
}
.list_wrap {
  background-color: #fff;
  padding: 50px;
}
</style>