<template>
  <div v-loading="loading">
    <el-button type="warning" plain @click="$router.go(-1)">返回</el-button>
    <div v-if="list.length != 0">
      <el-table :data="list" style="width: 100%">
        <el-table-column
          v-for="(item, index) in title"
          :key="index"
          :prop="item.field"
          :label="item.name"
        >
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="count"
          :current-page="page"
          :page-size="15"
          @current-change="switchFn"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else style="padding: 180px 0">
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import { home_info } from "@/api/data_board";
export default {
  data() {
    return {
      loading: false,
      page: 1,
      list: [],
      title: [],
      count: 0,
    };
  },
  methods: {
    //  切换分页
    switchFn(val) {
      this.page = val;
      this.home_info();
    },
    // 列表
    home_info() {
      this.loading = true;
      home_info({
        page: this.page - 1,
        select_data: this.$route.query.select_data,
      }).then((res) => {
        if (!res.code) {
          this.list = res.data.return_list;
          this.title = res.data.title;
          this.count = res.data.count;
          this.loading = false;
        }
      });
    },
  },
  created() {
    this.home_info();
  },
};
</script>

<style lang="less" scope>
.pagination {
  padding: 20px;
  display: flex;
  justify-content: center;
}
</style>