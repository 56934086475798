import Vue from 'vue'
import VueRouter from 'vue-router'
// 登录
import Login from '@/pages/login/login'
// 用户协议
import Agree from '@/pages/login/agree'
// 隐私政策
import Conceal from '@/pages/login/conceal'
// 登录后首页
import Company from '@/pages/company/company'
// 数据看板
import Data_board from '@/pages/data_board/data_board'
// 数据看板详情
import Data_details from '@/pages/data_board/data_details'
// 项目管理
import Project from '@/pages/project/project'
// -- 我的项目-项目列表
import Position from '@/pages/project/position'
// -- 我的项目-上岗零工详情
import OddjobInfo from '@/pages/project/oddjobInfo'
// -- 我的项目-项目费用明细
import Servicecharge from '@/pages/project/servicecharge'

// 雷达线索
import Radar_cue from '@/pages/radar_cue/radar_cue'
// 认证管理
import Authentication from '@/pages/authentication/authentication'
// 工资管理
import Settlement from '@/pages/settlement/settlement'
// --工资管理 结算详情列表
import Sett_details_list from '@/pages/settlement/sett_details_list.vue'
// 账号管理
import Settings from '@/pages/settings/settings'


// 钱包明细
import Wallet from '@/pages/wallet/wallet'


Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: '登录',
    component: Login
  },
  // 用户协议
  {
    path: '/agree',
    name: '用户协议',
    component: Agree
  },
  // 隐私政策
  {
    path: '/conceal',
    name: '隐私政策',
    component: Conceal
  }, {
    path: '/company',
    name: '首页',
    component: Company,
    redirect: '/company/data_board',
    children: [
      //数据看板
      {
        path: '/company/data_board',
        name: '数据看板',
        component: Data_board,
      },
      //数据看板详情
      {
        path: '/company/data_details',
        name: '数据看板详情',
        component: Data_details,
      },
      // 项目管理
      {
        path: '/company/project',
        name: '项目管理',
        component: Project,
      },
      {
        // 项目列表
        path: '/company/position',
        name: '项目列表',
        component: Position,
      }, {
        // 零工上岗详情
        path: '/company/oddjobInfo',
        name: '零工上岗详情',
        component: OddjobInfo,
      },
      // 认证管理
      {
        path: '/company/authentication',
        name: '认证管理',
        component: Authentication,
      },
      // 雷达线索
      {
        path: '/company/radar_cue',
        name: '雷达线索',
        component: Radar_cue,
      },

      // 工资管理
      {
        path: '/company/settlement',
        name: '工资管理',
        component: Settlement,
      },

      // 项目费用明细
      {
        path: '/company/servicecharge',
        name: '项目费用明细',
        component: Servicecharge,
      },
      // 结算详情列表
      {
        path: '/company/sett_details_list',
        name: '结算详情列表',
        component: Sett_details_list
      },

      // 钱包明细
      {
        path: '/company/wallet',
        name: '钱包明细',
        component: Wallet,
      },
      // 账号管理
      {
        path: '/company/settings',
        name: '账号管理',
        component: Settings,
      },
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router